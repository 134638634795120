import { BluetoothSerial } from "@ionic-native/bluetooth-serial";
import { useWindowSize } from "@react-hook/window-size";
import moment from "moment";
import { Badge } from "primereact/badge";
import React, { useState } from "react";
import { BsPrinterFill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";
import FilterModal from "./FilterModal";
import ItemwiseCard from "./ItemwiseCard";

const backend = new Backend();

export default function ItemWiseReport() {
  const [width, height] = useWindowSize();
  const [itemwiseData, setItemwiseData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [arrayData, setArrayData] = React.useState([]);
  const [total, setTotal] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filterDate, setFilterDate] = React.useState([new Date(), new Date()]);
  const [modal, setModal] = useState(false);
  const scrollheight = height - 250;
  const [selectBluetooth, setSelectBluetooth] = React.useState([]);

  React.useEffect(() => {
    load_itemwise_report();
  }, []);

  const load_itemwise_report = (date, filter) => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
      filter_id: filter?.admin_id > 0 ? filter?.admin_id : 0,
      from_date: date
        ? moment(date[0]).format("YYYY-MM-DD 00:00:00")
        : new Date(),
      to_date: date
        ? moment(date[1]).format("YYYY-MM-DD 23:59:59")
        : new Date(),
    };

    if (localStorage.getItem("superAdmin") == "1") {
      backend.load_itemwise_report_sa(data).then((r) => {
        if (r.error == "False") {
          setItemwiseData(r.itemwise);
          setTotal(r.total);
          setArrayData(r.outlet);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    } else {
      backend.load_itemwise_report(data).then((r) => {
        if (r.error == "False") {
          setItemwiseData(r.itemwise);
          setTotal(r.total);
          setArrayData(r.captain);
          setFilterDate(date);
          setFilterData(filter);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("bluetooth")) {
      setSelectBluetooth(JSON.parse(localStorage.getItem("bluetooth")));
    }
  }, []);

  const bluetoothTest = () => {
    if (selectBluetooth.length <= 0) {
      alert("Please select printer or turn on your bluetooth");
    } else {
      BluetoothSerial.isEnabled().then(
        (e) => {
          // alert(e);
          bluetoothIsConnected();
        },
        (e) => {
          // alert(e);
          enableBluetooth();
        }
      );
    }
  };

  const enableBluetooth = () => {
    BluetoothSerial.enable().then(
      (e) => {
        // alert(e);
        bluetoothIsConnected();
      },
      (e) => {
        alert("Please Turn On Bluetooth Manually");
        // this.connectBluetooth();
      }
    );
  };

  const bluetoothIsConnected = () => {
    BluetoothSerial.isConnected().then(
      (e) => {
        // alert(e);
        testPrintBluetooth();
      },
      (e) => {
        // alert(e);
        connectBluetooth();
      }
    );
  };

  const connectBluetooth = () => {
    BluetoothSerial.connect(selectBluetooth.address).subscribe(
      (e) => testPrintBluetooth(),
      (r) => console.log(r)
    );
  };

  const testPrintBluetooth = () => {
    var adminData = JSON.parse(localStorage.getItem("admin_data"));
    var printItems = itemwiseData;

    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write(Buffer.from("1B2130", "hex"));
    BluetoothSerial.write(adminData.company_name + "\n");
    BluetoothSerial.write(Buffer.from("1B2100", "hex"));

    BluetoothSerial.write("-".repeat(30) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));

    if (localStorage.getItem("printDate") != "") {
      BluetoothSerial.write(
        "DATE: " + moment().format(localStorage.getItem("printDate")) + "\n"
      );
    }

    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));
    BluetoothSerial.write(
      "Item Name" +
        " ".repeat(6) +
        " " +
        "Qty" +
        " " +
        "Rate" +
        " " +
        "    " +
        "Amt" +
        "\n"
    );
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));

    printItems.map((r) => {
      r.qty = r.total_qty == "" ? "" : parseFloat(r.total_qty);
      r.amt = r.total_amount == "" ? "" : parseFloat(r.total_amount);
      r.rate = r.product_rate == "" ? "" : parseFloat(r.product_rate);
      var qtystr = "";
      var ratestr = "";
      var amtstr = "";
      var regular = false;

      var varnamelength = r.product_name.length;
      var qtylen = r.qty.toString().length;

      var ratelen = r.rate.toString().length;
      var amtlen = r.amt.toString().length;
      var name1 = "";
      var name2 = "";
      if (varnamelength > 15) {
        name1 = r.product_name.substring(0, 15);
        name2 = r.product_name.substring(15, 30);
      } else {
        name1 = r.product_name;
      }

      if (name1.length == 15) {
      } else {
        name1 = name1 + " ".repeat(15 - name1.length);
      }

      //name 15 characters

      if (qtylen > 4) {
        regular = false;
        qtystr = r.qty.toString();
      } else {
        if (qtylen == 4) {
          qtystr = r.qty.toString();
        } else {
          qtystr = r.qty.toString() + " ".repeat(4 - r.qty.toString().length);
        }
      }

      if (ratelen > 4) {
        regular = false;
        ratestr = r.rate.toString();
      } else {
        if (ratelen == 4) {
          ratestr = r.rate.toString();
        } else {
          ratestr =
            r.rate.toString() + " ".repeat(4 - r.rate.toString().length);
        }
      }

      if (r.amt.toString().length > 6) {
        regular = false;
        amtstr = r.amt.toString();
      } else {
        if (r.amt.toString().length == 6) {
          amtstr = r.amt.toString();
        } else {
          amtstr = " ".repeat(6 - r.amt.toString().length) + r.amt.toString();
        }
      }

      if (regular == true) {
        BluetoothSerial.write(
          name1 + " " + qtystr + " " + ratestr + " " + amtstr + "\n"
        );
        if (name2 != "") {
          BluetoothSerial.write(name2 + "\n");
        }
      } else {
        BluetoothSerial.write(name1 + " " + name2 + "\n");
        qtystr = r.total_qty == "" ? "" : qtystr;
        ratestr = r.product_rate == "" ? "" : " x " + ratestr;
        amtstr = r.total_amount == "" ? "" : " = " + amtstr;
        BluetoothSerial.write(qtystr + ratestr + amtstr + "\n");
      }
      regular = false;
    });

    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    // BluetoothSerial.write(Buffer.from("1B6102", "hex"));
    // BluetoothSerial.write(Buffer.from("1B2161", "hex"));

    // BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    // BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    // BluetoothSerial.write("-".repeat(32) + "\n");

    if (localStorage.getItem("printFooter")) {
      let footer = localStorage.getItem("printFooter");
      BluetoothSerial.write(footer + "\n");
    }
    // var settings = JSON.parse(this.state.bluetoothsetting);
    var settings = {
      largefont: false,
      workerid: false,
      time_of_order: false,
      linefeeds:
        localStorage.getItem("printGapLines") > 0
          ? localStorage.getItem("printGapLines")
          : 3,
    };

    if (settings.linefeeds > 0) {
      for (var i = 0; i < settings.linefeeds; i++) {
        BluetoothSerial.write(Buffer.from("\n ", "ascii"));
      }
    }

    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));
  };

  return (
    <>
      <div style={{ height: height, width: width, overflowY: "hidden" }}>
        <div
          style={{
            height: 60,
            width: width,
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div
            style={{
              width: 60,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "gray",
            }}
            onClick={() => window.history.back()}
          >
            <MdArrowBackIos fontSize={25} />
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            ItemWise Reports
          </div>
          <div
            style={{
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Badge
              onClick={() => bluetoothTest()}
              style={{
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                background: "white",
              }}
              value={<BsPrinterFill size={25} color="var(--primary-color)" />}
            />
          </div>
          <div
            style={{
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Badge
              onClick={() => setModal(true)}
              style={{
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                background: "white",
              }}
              value={<IoMdMore size={25} color="var(--primary-color)" />}
            />
          </div>
        </div>

        <div
          style={{
            height: 75,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 13, color: "gray", fontWeight: "bold" }}>
            Net Amount
          </div>
          <div
            style={{
              fontSize: 25,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {total.invoice_net_amount ? total.invoice_net_amount : 0}
          </div>
        </div>
        <div style={{ height: 60, display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              flex: 1,

              borderRightStyle: "solid",
              borderRightWidth: 0.5,
            }}
          >
            <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
              Total Qty
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {total.total_qty}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              flex: 1,
            }}
          >
            <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
              Total
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {total.total_amount}
            </div>
          </div>
        </div>

        <div
          style={{
            padding: 5,
            display: "flex",
            fontWeight: "bold",
            background: "var(--primary-color)",
            color: "white",

            paddingRight: 10,
          }}
        >
          <div style={{ fontSize: 14, flex: 2, textAlign: "left" }}>
            PRODUCT
          </div>
          <div style={{ fontSize: 14, flex: 1 }}>QTY</div>
          <div style={{ fontSize: 14, flex: 1 }}>RATE</div>
          <div style={{ fontSize: 14, flex: 1, textAlign: "right" }}>
            AMOUNT
          </div>
        </div>

        <div style={{ height: scrollheight, overflowY: "scroll" }}>
          {itemwiseData.map((i, t) => (
            <ItemwiseCard data={i} />
          ))}
        </div>
        {/* <div
          style={{
            padding: 5,
            display: "flex",
            fontWeight: "bold",
            background: "lightgray",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div style={{ flex: 2, textAlign: "left" }}>TOTAL</div>
          <div style={{ flex: 1 }}>{total.total_qty}</div>
          <div style={{ flex: 1, textAlign: "right" }}>
            {total.total_amount}
          </div>
        </div> */}
      </div>
      <FilterModal
        arrayData={arrayData}
        visible={modal}
        filterDate={filterDate}
        filter={(date, id) => load_itemwise_report(date, id)}
        closeModal={() => setModal(false)}
      />
    </>
  );
}
