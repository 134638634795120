import React from "react";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { FaCircleArrowRight } from "react-icons/fa6";
import { useSelector } from "react-redux";

export default function PosBar({
  totalQuantity,
  totalAmount,
  createInvoice,
  totalGst,
  isLoading,
}) {
  return (
    <div
      style={{
        height: 60,
        background: "var(--primary-color)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 150,
          display: "flex",
          height: "100%",
          padding: 10,
          flexDirection: "column",
          alignItems: "flex-start",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div style={{ fontSize: 12, color: "white" }}>
          {totalQuantity} Items
        </div>
        <div style={{ fontSize: 20, color: "white", fontWeight: "bold" }}>
          ₹{parseFloat(totalAmount).toFixed(2)}
        </div>
      </div>
      <div
        style={{ flex: 1, fontSize: 14, color: "white", fontWeight: "bold" }}
      >
        {JSON.parse(localStorage.getItem("admin_data"))?.gst_active != 0 ? 
        <>
        <div>GST</div>
        {JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 2 ? (
          <div>
            {parseFloat(totalGst).toFixed(2)}
          </div>
        ) : (
          <div>
            {parseFloat(
              (parseFloat(totalAmount).toFixed(2) *
                JSON.parse(localStorage.getItem("admin_data"))?.gst_percent) /
                100
            ).toFixed(2)}
          </div>
      )}{" "} 
        </>
        :null}
      </div>
      {isLoading ? (
        <div
          style={{
            width: 100,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Creating Bill...
        </div> // Display loading indicator if isLoading is true
      ) : (
        <div
          style={{
            width: 100,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
          }}
          onClick={() => createInvoice()}
        >
          Print Bill
          <div
            style={{
              paddingLeft: 5,
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaCircleArrowRight />
          </div>
        </div>
      )}
    </div>
  );
}
