import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import Backend from "../Backend/Backend";

const backend = new Backend();

export default function PrintModal({
  visible,
  closeModal,
  billNo,
  rePrintBill,
  downloadPdf,
}) {
  const footerContent = (
    <div>
      <Button
        label="PDF"
        icon="pi pi-check"
        severity="warning"
        style={{ color: "#ffffff" }}
        onClick={() => downloadPdf()}
      />
      <Button
        label="Reprint"
        icon="pi pi-check"
        onClick={() => rePrintBill()}
      />
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header={" Invoice No : " + billNo}
      visible={visible}
      style={{ width: "90%" }}
      onHide={() => closeModal(false)}
      position={"center"}
      footer={footerContent}
      draggable={false}
      resizable={false}
    ></Dialog>
  );
}
