import { Badge } from "primereact/badge";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProductsCard({ data, index }) {
  const navigation = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        padding: 5,

        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
      }}
    >
      <div style={{ width: 40, fontWeight: "bold", alignSelf: "center" }}>
        {index}
      </div>
      <div style={{ flex: 2, textAlign: "left" }}>
        <div
          className="pcolor"
          style={{ fontSize: 18, fontWeight: "bold", display: "flex" }}
        >
          {data.product_name}{" "}
          <div
            style={{
              padding: 2,
              width: 20,
              borderRadius: 3,
              fontSize: 13,
              marginLeft: 5,
              color: "white",
              aspectRatio: 1,
              background:
                data.status == 1 ? "var(--green-400)" : "var(--red-400)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.status == 1 ? "A" : "D"}
          </div>
        </div>

        <div className="pcolor" style={{ fontSize: 14, fontWeight: "normal" }}>
          {data.category_name}
        </div>

        {data.product_name != "" ? (
          <div style={{ fontSize: 14, fontWeight: "normal" }}>
            {data.unicode_name}
          </div>
        ) : null}
        <div
          style={{
            fontSize: 14,
            fontWeight: "normal",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: 10,
          }}
        ></div>

        <div style={{ fontWeight: "bold", color: "gray" }}>
          ₹ {parseFloat(data.price)}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
          paddingRight: 10,
        }}
      >
        <div>
          <Badge
            onClick={() =>
              navigation("/editProduct", { state: { data: data } })
            }
            value={"EDIT"}
            style={{ borderRadius: 3 }}
          ></Badge>
        </div>
        <div style={{ fontSize: 12, marginTop: 5 }}>STOCK : 3560</div>
      </div>
    </div>
  );
}
