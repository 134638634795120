import React from "react";
import { Badge } from "primereact/badge";
import { useNavigate } from "react-router-dom";

export default function CaptainCard({ index, data }) {
  const navigation = useNavigate();
  return (
    <div
      style={{
        minHeight: 50,
        display: "flex",
        position: "relative",
        borderBottomStyle: "solid",
        justifyContent: "center",
        alignItems: "center",
        borderBottomWidth: 0.5,
      }}
    >
      <div
        style={{
          width: 50,
          height: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        {index}
      </div>
      <div
        style={{
          flex: 1,
          height: 50,
          display: "flex",
          flexDirection: "column",
          padding: 5,
          alignItems: "flex-start",
        }}
        onClick={() =>
          localStorage.getItem("admin_id") == data.id
            ? alert("You cannot edit youself")
            : navigation("/editCaptain", { state: { data: data } })
        }
      >
        <div className="pcolor" style={{ fontSize: 18, fontWeight: "bold" }}>
          {data.name}
        </div>

        <div
          style={{
            fontSize: 14,
            fontWeight: "normal",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: 10,
            color: "gray",
          }}
        >
          <div style={{ fontWeight: "bold" }}> Mob: {data.mobile}</div>
        </div>
      </div>

      <Badge
        value={data.status == 1 ? "A" : "D"}
        severity={data.status == 1 ? "success" : "danger"}
        style={{
          position: "absolute",
          right: 15,
          borderRadius: 5,
          alignSelf: "center",
          backgroundColor:
            data.status == 1 ? "var(--primary-color)" : "var(--red-500)",
        }}
      ></Badge>
    </div>
  );
}
