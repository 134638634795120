import { InputText } from "primereact/inputtext";
import React from "react";
import Backend from "../Backend/Backend";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const backend = new Backend();

export default function VariableRate({
  visible,
  closeModal,
  rate,
  addToVariableCart,
}) {
  const [amount, setAmount] = React.useState("");
  const addvaritabklecart = () => {
    if (parseFloat(amount) > 0) {
      addToVariableCart(amount);
      closeModal();
    }
  };
  React.useEffect(() => {
    setAmount("");
  }, [visible]);

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={addvaritabklecart}
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header="Variable Rate"
      visible={visible}
      style={{ width: "90%" }}
      onHide={() => closeModal(false)}
      position={"bottom"}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Amount
        </div>
        <InputText
          type="number"
          value={parseFloat(amount)}
          onChange={(e) => setAmount(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
    </Dialog>
  );
}
