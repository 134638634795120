import React, { Component } from "react";
import { MdOutlineBluetoothSearching } from "react-icons/md";
// import { Main_color } from "../../Theme/Color";
import { BluetoothSerial } from "@ionic-native/bluetooth-serial";
// import { Permissions } from "@capacitor/core";

export default class PrinterSheet extends Component {
  state = {
    printer_list: [],
  };

  componentDidMount() {
    this.isEnableBluetooth();
    this.requestBluetoothPermissions();
  }

  isEnableBluetooth() {
    BluetoothSerial.isEnabled().then(
      (e) => {
        this.scanBluetooth();
      },
      (e) => {
        this.enableBluetooth();
      }
    );
  }
  async requestBluetoothPermissions() {
    try {
      const status = await BluetoothSerial.requestPermission();
      if (status === "granted") {
        alert("hi");
        // Permission granted, proceed with Bluetooth operations
      } else {
        alert("hi2");
        // Permission denied, handle accordingly
      }
    } catch (error) {
      console.error("Error requesting Bluetooth permissions", error);
    }
  }

  enableBluetooth() {
    BluetoothSerial.enable().then(
      (e) => {
        this.scanBluetooth();
      },
      (e) => {
        alert(e);
      }
    );
  }

  scanBluetooth() {
    BluetoothSerial.list().then(
      (e) => {
        this.setState({
          printer_list: e,
        });
      },
      (e) => {
        alert("Error : Cannot List Paired Bluetooth Devices");
      }
    );
  }

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div
          style={{
            height: 55,
            backgroundColor: "var(--primary-color)",
            padding: 5,
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <h4 style={{ color: "white", flex: 1 }}>Select Bluetooth Printer </h4>
          <h4
            style={{ color: "#ffff" }}
            onClick={() => this.props.closeSheet()}
          >
            <div style={{ fontSize: 12 }}>CLICK TO CLOSE</div>
          </h4>
        </div>

        <div style={{ overflowY: "scroll", height: "100vh", padding: 5 }}>
          {this.state.printer_list.map((i, t) => (
            <div
              style={{
                display: "flex",
                padding: 10,
                borderBottomWidth: 0.5,
                borderBottomStyle: "solid",
                marginTop: 5,
              }}
              onClick={() => {
                this.props.selectBluetooth(i);
                this.props.closeSheet();
              }}
            >
              <div
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                  flex: 1,
                }}
              >
                {i.name}
              </div>
              <div style={{ fontWeight: "bold", color: "gray" }}>
                <MdOutlineBluetoothSearching style={{ fontSize: 25 }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
