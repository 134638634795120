import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import Backend from "../Backend/Backend";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useNavigate } from "react-router-dom";
import CaptainCard from "./CaptainCard";
import Header from "../Component/Header";

const backend = new Backend();
export default function Captains() {
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [captainData, setCaptainData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    load_captain();
  }, []);

  const load_captain = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend
      .load_captain(data)
      .then((r) => {
        if (r.error == "False") {
          setCaptainData(r.captains);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      })
      .catch((err) => alert(err));
  };
  return (
    <>
      <div style={{ height: height, width: width }}>
        <Header name="Captains" onClick={() => navigation("/addcaptain")} />
        {captainData.map((i, t) => (
          <CaptainCard key={i.id} index={t + 1} data={i} />
        ))}
      </div>
    </>
  );
}
