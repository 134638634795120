import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function SalesReportCard({ data, delete_invoice }) {
  const navigation = useNavigate();

  const billnavigate = () => {
    localStorage.setItem("TEMPBILL", JSON.stringify(data));
    navigation("/BillScreen");
  };

  return (
    <div
      style={{
        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
        padding: 5,
        height: 50,
      }}
      onClick={billnavigate}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "left",
            fontWeight: "bold",
            color: data.status == "0" ? "black" : "var(--primary-color)",
            textDecoration: data.status == "0" ? "line-through" : "",
          }}
        >
          BILL #{data.bill_no}
        </div>
        <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
          {" "}
          {moment(data.date_created).format("DD-MMM-YY HH:mm")}
        </div>
      </div>

      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ flex: 1, textAlign: "left", fontSize: 14 }}>
          Net Amt. {data.net_amount}
        </div>
        <div style={{ flex: 1, textAlign: "right", fontSize: 14 }}>
          Total: {data.total}
        </div>
      </div>
    </div>
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "space-between",
    //     padding: 10,
    //   }}
    // >
    //   {data.id}

    //   {data.status == 1 ? (
    //     <Button
    //       severity="danger"
    //       label="Delete"
    //       icon="pi pi-check"
    //       onClick={() => delete_invoice(data.id)}
    //       style={{ color: "white" }}
    //     />
    //   ) : (
    //     <div>deleted</div>
    //   )}
    // </div>
  );
}
