const api_link = "https://simplysales.postick.co.in/ss";
const access_key_php =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381-77004ea213d5fc71acf74a8c9c6795fb";

export default class Backend {
  login(data) {
    return fetch(api_link + "/login", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_gst_report_sa(data) {
    return fetch(api_link + "/load_gst_report_sa", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_gst_report(data) {
    return fetch(api_link + "/load_gst_report", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  register(data) {
    return fetch(api_link + "/register", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  forgot_password(data) {
    return fetch(api_link + "/forgot_password", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  dashboard(data) {
    return fetch(api_link + "/home", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  dashboard_super_admin(data) {
    return fetch(api_link + "/home_super_admin", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_product(data) {
    return fetch(api_link + "/add_product", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_product(data) {
    return fetch(api_link + "/load_product", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_category(data) {
    return fetch(api_link + "/load_category", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_category(data) {
    return fetch(api_link + "/add_category", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_pos_data(data) {
    return fetch(api_link + "/load_pos_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  create_invoice(data) {
    return fetch(api_link + "/create_invoice", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_expense(data) {
    return fetch(api_link + "/add_expense", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_sidebar_data(data) {
    return fetch(api_link + "/load_sidebar_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_customer_details(data) {
    return fetch(api_link + "/load_customer_details", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_sales_report(data) {
    return fetch(api_link + "/load_sales_report", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_all_invoice(data) {
    return fetch(api_link + "/delete_all_invoice", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_invoice(data) {
    return fetch(api_link + "/delete_invoice", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_expense_report(data) {
    return fetch(api_link + "/load_expense_report", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }

  delete_expense(data) {
    return fetch(api_link + "/delete_expense", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_expense_report_sa(data) {
    return fetch(api_link + "/load_expense_report_sa", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_sales_report_sa(data) {
    return fetch(api_link + "/load_sales_report_sa", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_itemwise_report_sa(data) {
    return fetch(api_link + "/load_itemwise_report_sa", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_itemwise_report(data) {
    return fetch(api_link + "/load_itemwise_report", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_captain(data) {
    return fetch(api_link + "/add_captain", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_captain(data) {
    return fetch(api_link + "/load_captains", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_captain_data(data) {
    return fetch(api_link + "/load_captain_data", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_captain(data) {
    return fetch(api_link + "/edit_captain", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_captain(data) {
    return fetch(api_link + "/delete_captain", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_product(data) {
    return fetch(api_link + "/edit_product", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_product(data) {
    return fetch(api_link + "/delete_product", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_category(data) {
    return fetch(api_link + "/edit_category", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_category(data) {
    return fetch(api_link + "/delete_category", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  sync_all(data) {
    return fetch(api_link + "/sync_all", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_profile(data) {
    return fetch(api_link + "/load_profile", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_profile(data) {
    return fetch(api_link + "/edit_profile", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  load_payment_method(data) {
    return fetch(api_link + "/load_payment_method", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  add_payment_method(data) {
    return fetch(api_link + "/add_payment_method", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  edit_payment_method(data) {
    return fetch(api_link + "/edit_payment_method", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
  delete_payment_method(data) {
    return fetch(api_link + "/delete_payment_method", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // accept: "application/json",
      },
      body: JSON.stringify({
        accesskey: access_key_php,
        data: data,
      }),
    }).then((response) => response.json());
  }
}
