import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import React from "react";

export default function ExpenseReportCard({ data, delete_expense }) {
  // alert(JSON.stringify(data));
  return (
    <div
      style={{
        display: "flex",
        padding: 5,
        borderBottomWidth: 0.5,
        borderBottomStyle: "solid",
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {/* <div style={{ width: 40 }}>{data.id}</div> */}
      <div
        style={{
          flex: 4,
          textAlign: "left",
          fontWeight: "bold",
          paddingLeft: 5,
        }}
      >
        {data.details}
        <br />
        <div style={{ fontSize: 12, color: "gray" }}>{data.captain_name}</div>
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.amount}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          if (data.status == 1) {
            delete_expense(data.id);
          }
        }}
      >
        {data.status == 1 ? (
          <Badge
            value={"DELETE"}
            style={{
              padding: 0,
              margin: 0,
              fontWeight: "bold",
              borderRadius: 3,
              paddingLeft: 5,
              paddingRight: 5,
            }}
            severity={"danger"}
          ></Badge>
        ) : (
          <div
            style={{
              fontSize: 12,
              fontWeight: "bold",
              justifySelf: "center",
              color: "var(--primary-color)",
            }}
          >
            DELETED
          </div>
        )}
      </div>
    </div>
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "space-between",
    //     padding: 10,
    //   }}
    // >
    //   {data.id}
    //   {data.status == 1 ? (
    //     <Button
    //       severity="danger"
    //       label="Delete"
    //       icon="pi pi-check"
    //       onClick={() => delete_expense(data.id)}
    //       style={{ color: "white" }}
    //     />
    //   ) : (
    //     <div>deleted</div>
    //   )}
    // </div>
  );
}
