import React from "react";

export default function ItemwiseCard({ data }) {
  return (
    <div style={{ padding: 5, display: "flex", paddingRight: 10 }}>
      <div style={{ flex: 2, textAlign: "left", fontWeight: "bold" }}>
        {data.product_name}
      </div>
      <div style={{ flex: 1 }}>{data.total_qty}</div>
      <div style={{ flex: 1 }}>{data.product_rate}</div>
      <div style={{ flex: 1, textAlign: "right", fontWeight: "bold" }}>
        {data.total_amount}
      </div>
    </div>
  );
}
