import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import Backend from "../Backend/Backend";
import "react-spring-bottom-sheet/dist/style.css";
import { useNavigate } from "react-router-dom";
import Header from "../Component/Header";
import PaymentMethodCard from "./PaymentMethodCard";

const backend = new Backend();
export default function PaymentMethod() {
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [paymentData, setPaymentData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    load_payment_method();
  }, []);

  const load_payment_method = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend
      .load_payment_method(data)
      .then((r) => {
        if (r.error == "False") {
          setPaymentData(r.payment);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      })
      .catch((err) => alert(err));
  };
  return (
    <>
      <div style={{ height: height, width: width }}>
        <Header
          name="Payment Methods"
          onClick={() => navigation("/addPaymentMethod")}
        />
        {paymentData.map((i, t) => (
          <PaymentMethodCard key={i.id} index={t + 1} data={i} />
        ))}
      </div>
    </>
  );
}
