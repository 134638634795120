import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Backend from "../Backend/Backend";
import { Dropdown } from "primereact/dropdown";

const backend = new Backend();
export default function EditProduct() {
  const [width, height] = useWindowSize();
  const location = useLocation();
  const data = location.state.data;

  const [categoryData, setCategoryData] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState({});

  const [productName, setProductName] = React.useState(data?.product_name);
  const [unicodeName, setUnicodeName] = React.useState(data?.unicode_name);
  const [barcode, setBarcode] = React.useState(data?.barcode);
  const [hsnCode, setHsnCode] = React.useState(data?.hsn_code);
  const [price, setPrice] = React.useState(data?.price);
  const [gst, setGst] = React.useState(parseFloat(data?.gst).toString());
  const [fav, setFav] = React.useState(data?.fav == 1 ? true : false);
  const [status, setStatus] = React.useState(data?.status == 1 ? true : false);
  const [variableRate, setVariableRate] = React.useState(
    data?.variable_price == 1 ? true : false
  );
  const [loading, setLoading] = React.useState(false);
  const dropDownArrayGst = [
    { name: "0%", value: "0" },
    { name: "5%", value: "5" },
    { name: "12%", value: "12" },
    { name: "18%", value: "18" },
    { name: "28%", value: "28" }
  ];


  React.useEffect(() => {
    load_category();
  }, []);

  const load_category = () => {
    setLoading(true);
    let dataSend = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend.load_category(dataSend).then((r) => {
      if (r.error == "False") {
        setCategoryData(r.category);
        let selectedCategory = r.category.find(
          (category) => category.id === data?.category_id
        );
        setSelectedCategory(selectedCategory);
      } else {
        window.alert(r.message);
      }
      setLoading(false);
    });
  };

  const edit_product = () => {
    if (productName == "" || productName.length < 1) {
      window.alert("Enter Product Name Properly");
    } else if (price <= 0) {
      window.alert("Enter Product Price Greater Than 0");
    } else if (gst < 0 || gst > 100) {
      window.alert("Enter Product GST Between 0 and 100");
    } else if (selectedCategory?.id <= 0) {
      window.alert("Select category");
    } else if (selectedCategory?.name <= 0) {
      window.alert("Select category");
    } else {
      setLoading(true);
      let dataSend = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        product_id: data?.id,
        product_name: productName,
        category_name: selectedCategory?.name,
        category_id: selectedCategory?.id,
        price: price,
        gst:gst,
        hsn_code:hsnCode,
        unicode_name: unicodeName,
        barcode: barcode,
        fav: fav == true ? 1 : 0,
        status: status == true ? 1 : 0,
        variable_price: variableRate == true ? 1 : 0,
      };

      backend
        .edit_product(dataSend)
        .then((r) => {
          if (r.error == "False") {
            window.alert(r.message);
            window.history.back();
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        })
        .catch((err) => alert(err));
    }
  };

  const delete_product = () => {
    if (data?.id <= 0) {
      window.alert("Invalid product. please reload");
    } else {
      if (window.confirm("Do you really want to delete this product?")) {
        if (!loading) {
          setLoading(true);
          let dataSend = {
            authtoken: localStorage.getItem("admin_token"),
            admin_id: localStorage.getItem("admin_id"),
            product_id: data?.id,
          };
          backend.delete_product(dataSend).then((r) => {
            if (r.error == "False") {
              window.history.back();
              window.alert(r.message);
            } else {
              window.alert(r.message);
            }
            setLoading(false);
          });
        }
      }
    }
  };

  return (
    <div style={{ height: height, width: width }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          //   borderBottomStyle: "solid",
          //   borderBottomWidth: 0.5,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Edit Products
        </div>
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        ></div>
        <div
          style={{
            padding: 10,
            paddingTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={() => delete_product()}
            label="Delete"
            severity="danger"
            style={{ marginTop: 10, width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Product Name
        </div>
        <InputText
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Unicode Name
        </div>
        <InputText
          value={unicodeName}
          onChange={(e) => setUnicodeName(e.target.value)}
          placeholder="(Optional)"
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Barcode
        </div>
        <InputText
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          placeholder="(Optional)"
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          HSN Code
        </div>
        <InputText
          value={hsnCode}
          onChange={(e) => setHsnCode(e.target.value)}
          placeholder="(Optional)"
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Rate/Price
        </div>
        <InputText
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      {JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 2 ?
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
         Product  Gst
        </div>
        {/* <InputText
          value={gst}
          type="number"
          onChange={(e) => setGst(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        /> */}
        <Dropdown
          value={gst}
          onChange={(e) => setGst(e.value)}
          options={dropDownArrayGst}
          optionLabel="name"
          placeholder="Select GST slab"
          style={{ width: "100%" }}
        />
      </div>:null}
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Category
        </div>
        <Dropdown
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.value)}
          options={categoryData}
          optionLabel="name"
          style={{ width: "100%" }}
          placeholder="Select "
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Variable Price
        </div>
        <InputSwitch
          checked={variableRate}
          onChange={() => setVariableRate(!variableRate)}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Favorite
        </div>
        <InputSwitch checked={fav} onChange={() => setFav(!fav)} />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Status
        </div>
        <InputSwitch checked={status} onChange={() => setStatus(!status)} />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => edit_product()}
          label="Update"
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
    </div>
  );
}
