import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Backend from "../Backend/Backend";

const backend = new Backend();

export default function ForgotPassword() {
    const [width, height] = useWindowSize();
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        mobile: "",
        password: "",
        confirm_password: "",
    });
    const [showOTPField, setShowOTPField] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [otp, setOTP] = React.useState("");

    const formatFieldName = (fieldName) => {
        // Convert underscores to spaces
        let formattedName = fieldName.replace(/_/g, ' ');
        // Capitalize the first letter of each word
        formattedName = formattedName.replace(/\b\w/g, (match) => match.toUpperCase());
        return formattedName;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleGetOTPButtonClick = (resend) => {
        if (formData.mobile === "" || formData.password === "" || formData.confirm_password === "") {
            window.alert("Please fill all the details correctly.");
        } else if (formData.password !== formData.confirm_password) {
            window.alert("Password and confirm password do not match.");
        } else {
            // Add logic here to request OTP from backend
           
                if(!loading){
                    setLoading(true);
                let dataSend = {
                  authtoken: localStorage.getItem("admin_token"),
                  admin_id: localStorage.getItem("admin_id"),
                  otp:otp,resend:resend
                };
                Object.keys(formData).forEach((field) => {
                  dataSend[field] = formData[field];
                });
                console.log(dataSend)
                backend
                  .forgot_password(dataSend)
                  .then((r) => {
                    // alert(JSON.stringify(r));
                  
                    if (r.error == "False") {
                      window.alert(r.message);
                    }else if (r.error == "Changed") {
                        window.alert(r.message);
                        window.location.href = "/login";
                      } else {
                      window.alert(r.message);
                    }
                    timer()
                    // setLoading(false)
                  })
                  .catch((err) => alert(err));
              
            setShowOTPField(true); // Show the OTP field after clicking "Get OTP"
        }}
    };
    
const timer = ()=>{
    setTimeout(() => {
        setLoading(false);
      }, 3000);
}

    const savePassword = () => {
        // Add your logic here to save the password
        handleGetOTPButtonClick(0)
        // You can call backend APIs here to save the password
    };
    return (
        <div style={{ height: height }}>
            <div
                style={{
                    display: "flex",
                    padding: 10,
                    fontSize: 25,
                    fontWeight: "bold",
                }}
            >
                Forgot Password
            </div>
            <div>
                {Object.keys(formData).map((fieldName) => (
                    <div style={{ padding: 10 }} key={fieldName}>
                        <div
                            style={{
                                color: "var(--primary-color)",
                                fontSize: 18,
                                textAlign: "left",
                            }}
                        >
                            {formatFieldName(fieldName)}
                        </div>
                        <input
                            onChange={handleChange}
                            type={fieldName === "mobile" || fieldName === "otp" ? "number" : (fieldName === "password" || fieldName === "confirm_password" ? "password" : "text")}
                            id={fieldName}
                            name={fieldName}
                            value={formData[fieldName]}
                            style={{ marginTop: 5, width: "100%", height: 40 }}
                        />
                    </div>
                ))}
            </div>
            {showOTPField && ( 
               <div style={{ padding: 10 }}>
                    <div
                        style={{
                            color: "var(--primary-color)",
                            fontSize: 18,
                            textAlign: "left",
                            display:'flex',
                            justifyContent:'space-between'
                        }}
                    >
                        <span>Enter OTP</span>
                        <Button
                label={"Resend OTP"} // Change label based on showOTPField state
                onClick={()=> handleGetOTPButtonClick(1)} // If showOTPField is true, call savePassword, otherwise call handleGetOTPButtonClick
                style={{  width: "40%",height:25 ,backgroundColor:'white',color: "var(--primary-color)",}}
            />
                    </div>
                    <input
                        onChange={(e)=>setOTP(e.target.value)}
                        type="number"
                        id="otp"
                        name="otp"
                        value={otp}
                        style={{ marginTop: 5, width: "100%", height: 40 }}
                    />
                </div>
            )}
            {alertMessage && (
                <div style={{ padding: 10, color: "red" }}>{alertMessage}</div>
            )}
            <div
                style={{
                    padding: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    color: "var(--primary-color)"
                }}
            >
                <Button
                    label={showOTPField ? "Save" : "Get OTP"} // Change label based on showOTPField state
                    onClick={()=>showOTPField ? savePassword() : handleGetOTPButtonClick(0)} // If showOTPField is true, call savePassword, otherwise call handleGetOTPButtonClick
                    style={{ marginTop: 10, width: "100%" }}
                />
            </div>
        </div>
    );
}
