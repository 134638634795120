import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Backend from "../Backend/Backend";

const backend = new Backend();
export default function EditCaptain() {
  const [width, height] = useWindowSize();
  const location = useLocation();
  const data = location.state.data;

  const [name, setName] = React.useState(data?.name);
  const [password, setPassword] = React.useState("");
  const [mobile, setMobile] = React.useState(data?.mobile);
  const [permissionCaptain, setPermissionCaptain] = React.useState(
    JSON.parse(data?.permission).add_edit_captain == 1 ? true : false
  );
  const [permissionCategory, setPermissionCategory] = React.useState(
    JSON.parse(data?.permission).add_edit_category == 1 ? true : false
  );
  const [permissionProduct, setPermissionProduct] = React.useState(
    JSON.parse(data?.permission).add_edit_product == 1 ? true : false
  );
  const [permissionReport, setPermissionReport] = React.useState(
    JSON.parse(data?.permission).control_reports == 1 ? true : false
  );

  const [loading, setLoading] = React.useState(false);
  const [captainStatus, setCaptainStatus] = React.useState(
    data?.status == 1 ? true : false
  );

  React.useEffect(() => {
    // load_captain_data();
  }, []);

  const edit_captain = () => {
    if (name == "" || name.length < 1) {
      window.alert("Enter Captain Name Properly");
    } else if (data?.id <= 0) {
      window.alert("Invlaid Worker. please reload and try again!");
    } else if (password != "" && password.length < 3) {
      window.alert("Enter Password atleast 3 Character");
    } else if (mobile == "" || mobile.length != 10) {
      window.alert("Enter Mobile Number properly with 10 digit");
    } else {
      setLoading(true);
      let dataSend = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        captain_name: name,
        captain_id: data?.id,
        password: password,
        status: captainStatus == true ? 1 : 0,
        product_permission: permissionProduct == true ? 1 : 0,
        category_permission: permissionCategory == true ? 1 : 0,
        report_permission: permissionReport == true ? 1 : 0,
        captain_permission: permissionCaptain == true ? 1 : 0,
      };

      backend
        .edit_captain(dataSend)
        .then((r) => {
          if (r.error == "False") {
            window.alert(r.message);
            window.history.back();
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        })
        .catch((err) => alert(err));
    }
  };

  const delete_captain = () => {
    if (data?.id <= 0) {
      window.alert("Invalid captain. please reload");
    } else {
      if (window.confirm("Do you really want to delete this captain?")) {
        if (!loading) {
          setLoading(true);
          let dataSend = {
            authtoken: localStorage.getItem("admin_token"),
            admin_id: localStorage.getItem("admin_id"),
            captain_id: data?.id,
          };
          backend.delete_captain(dataSend).then((r) => {
            if (r.error == "False") {
              window.history.back();
              window.alert(r.message);
            } else {
              window.alert(r.message);
            }
            setLoading(false);
          });
        }
      }
    }
  };

  return (
    <div style={{ height: height, width: width }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
          marginBottom: 10,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            width: "100%",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Edit Captain
        </div>
        <div
          style={{
            padding: 10,
            paddingTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={() => delete_captain()}
            label="Delete"
            severity="danger"
            style={{ marginTop: 10, width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Captain Name
        </div>
        <InputText
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Mobile
        </div>
        <InputText
          value={mobile}
          type="number"
          disabled
          onChange={(e) => setMobile(e.target.value)}
          style={{
            marginTop: 5,
            width: "100%",
            color: "var(--primary-color)",
            backgroundColor: "rgba(0,0,0,0.1)",
            fontWeight: "bolder",
          }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Password
        </div>
        <InputText
          value={password}
          placeholder="Keep it empty for same password"
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Status
        </div>
        <InputSwitch
          onChange={() => setCaptainStatus(!captainStatus)}
          checked={captainStatus == 1 ? true : false}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Permissions
        </div>
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Add/Edit Captain
        </div>
        <InputSwitch
          onChange={() => setPermissionCaptain(!permissionCaptain)}
          checked={permissionCaptain == 1 ? true : false}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Add/Edit Product
        </div>
        <InputSwitch
          onChange={() => setPermissionProduct(!permissionProduct)}
          checked={permissionProduct == 1 ? true : false}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Add/Edit Category
        </div>
        <InputSwitch
          onChange={() => setPermissionCategory(!permissionCategory)}
          checked={permissionCategory == 1 ? true : false}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Control Reports
        </div>
        <InputSwitch
          onChange={() => setPermissionReport(!permissionReport)}
          checked={permissionReport == 1 ? true : false}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => edit_captain()}
          label="Update"
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
    </div>
  );
}
