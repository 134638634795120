import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";

export default function FilterModal({
  visible,
  closeModal,
  arrayData,
  filter,
}) {
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [filterData, setFilterData] = useState({});

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
      />
      <Button
        label="Search"
        icon="pi pi-check"
        onClick={() => {
          filter(dates, filterData);
          closeModal();
        }}
        autoFocus
      />
    </div>
  );
  return (
    <Dialog
      header={"Filter"}
      visible={visible}
      style={{ width: "95%" }}
      onHide={closeModal}
      position={"bottom"}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          padding: 5,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 14,
          }}
        >
          {localStorage.getItem("superAdmin") == "1" ? "Outlet" : "Captain"}
        </div>
        <Dropdown
          value={filterData}
          onChange={(e) => {
            if (e.value == undefined) {
              setFilterData({});
            } else {
              setFilterData(e.value);
            }
          }}
          options={arrayData}
          showClear
          style={{ width: "100%" }}
          optionLabel="filter_name"
          placeholder="Select Here"
        />
      </div>
      <div
        style={{
          padding: 5,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 14,
          }}
        >
          SELECT RANGE OF DATE
        </div>

        <Calendar
          style={{ width: "100%" }}
          value={dates}
          onChange={(e) => setDates(e.value)}
          selectionMode="range"
          readOnlyInput
          dateFormat="dd-mm-yy"
        />
      </div>
    </Dialog>
  );
}
