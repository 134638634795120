import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdDelete,
  MdPointOfSale,
} from "react-icons/md";
import Backend from "../Backend/Backend";
import { Card } from "primereact/card";
import SalesReportCard from "./SalesReportCard";
import FilterModal from "./FilterModal";
import moment from "moment";
import { CgCopy } from "react-icons/cg";

import { Badge } from "primereact/badge";
import { IoMdMore } from "react-icons/io";
import MenuButton from "../Home/MenuButton";
import { FcBarChart } from "react-icons/fc";
import * as ExcelJS from "exceljs";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Capacitor, Plugins } from "@capacitor/core";
import { LocalNotifications } from "@capacitor/local-notifications";

const { Permissions } = Plugins;

const NewTheme = {
  MainColor: "#4a6ba1",
  MainColorExcel: "#4a6ba1",
  greencolor: "#019c6b",
  greencolorExcel: "#019c6b",
  redcolor: "#df1524",
  redcolorExcel: "#df1524",
  lightmain: "#edebfa",
  newredtext: "#DC143C",
  lightred: "#f7babe3b",
};
const backend = new Backend();
export default function GSTreport() {
  const [width, height] = useWindowSize();
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [invoiceTotal, setInvoiceTotal] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filterDate, setFilterDate] = React.useState([new Date(), new Date()]);
  const [modal, setModal] = React.useState(false);
  const [filterData, setFilterData] = React.useState([]);
  const [arrayData, setArrayData] = React.useState([]);
  const scrollheight = height - 200;

  React.useEffect(() => {
    load_gst_report();
  }, []);

  const handleClick = () => {
    alert("Coming soon");
  };

  const load_gst_report = (date, filter) => {
    if (!loading) {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        filter_id: filter?.admin_id > 0 ? filter?.admin_id : 0,
        from_date: date
          ? moment(date[0]).format("YYYY-MM-DD 00:00:00")
          : new Date(),
        to_date: date
          ? moment(date[1]).format("YYYY-MM-DD 23:59:59")
          : new Date(),
      };

      if (localStorage.getItem("superAdmin") == "1") {
        backend.load_gst_report_sa(data).then((r) => {
          if (r.error == "False") {
            setInvoiceData(r.invoices);
            setInvoiceTotal(r.total);
            setArrayData(r.outlet);
            setFilterDate(date);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      } else {
        backend.load_gst_report(data).then((r) => {
          if (r.error == "False") {
            setInvoiceData(r.invoices);
            setInvoiceTotal(r.total);
            setArrayData(r.captain);
            setFilterDate(date);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "bill_no",
      "invoice_date",
      "total",
      "gst",
      "roundoff",
      "net_amount",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Bill No.",
      "Invoice Date",
      "Total",
      "Gst",
      "Round Off",
      "Net Amount",
    ];

    const headerRow = worksheet.addRow(["GST - Transaction"]).commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:L${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:L${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    worksheet.addRow([1, "Total Bills", parseFloat(invoiceData.length)]);
    worksheet.addRow([
      2,
      "Total Net Amount",
      parseFloat(invoiceTotal.net_amount),
    ]);
    worksheet.addRow([3, "Total Gst", parseFloat(invoiceTotal.gst_total)]);
    worksheet.addRow([
      4,
      "From Date",
      filterDate
        ? moment(filterDate[0]).format("YYYY-MM-DD 00:00:00")
        : moment(new Date()).format("YYYY-MM-DD 00:00:00"),
    ]);
    worksheet.addRow([
      5,
      "To Date",
      filterDate
        ? moment(filterDate[1]).format("YYYY-MM-DD 23:59:59")
        : moment(new Date()).format("YYYY-MM-DD 23:59:59"),
    ]);

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    // Add column headers to the worksheet with display names
    const headers = worksheet.addRow(headerDisplayNames);

    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
    });

    // Extract only the desired columns from transactionData
    const filteredData = invoiceData.map((item) => {
      return colsToExport.reduce((acc, col) => {
        if (col === "buy_sell") {
          acc[col] = item[col] == 0 ? "Buy" : "Sell";
        } else {
          acc[col] = item[col];
        }
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    const colIndex = colsToExport.indexOf("bill_no");
    const totalIndex = colsToExport.indexOf("total");
    const gstIndex = colsToExport.indexOf("gst");
    const roundIndex = colsToExport.indexOf("roundoff");
    const netIndex = colsToExport.indexOf("net_amount");

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      //   const cellValue = row.getCell(colIndex + 1).value;
      const totalValue = row.getCell(totalIndex + 1).value;
      const gstValue = row.getCell(gstIndex + 1).value;
      const roundValue = row.getCell(roundIndex + 1).value;
      const netValue = row.getCell(netIndex + 1).value;

      // Format as numbers with two decimal places
      const formatAsNumber = (cell, value) => {
        if (value !== null && !isNaN(value) && rowNumber > 4) {
          cell.value = rowNumber > 9 ? Number(value) : value;
          cell.numFmt = rowNumber > 9 ? "#,##0.00" : undefined; // Adjust the number format as needed
        }
      };

      //   formatAsNumber(row.getCell(colIndex + 1), cellValue);
      formatAsNumber(row.getCell(totalIndex + 1), totalValue);
      formatAsNumber(row.getCell(gstIndex + 1), gstValue);
      formatAsNumber(row.getCell(roundIndex + 1), roundValue);
      formatAsNumber(row.getCell(netIndex + 1), netValue);
    });

    // Add empty rows between data and footer
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    var fileName = "GST Reports";

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const checkPermissions = async () => {
    const permissionStatus = await Filesystem.checkPermissions();

    if (permissionStatus.publicStorage !== "granted") {
      const permissionRequest = await Filesystem.requestPermissions();
      if (permissionRequest.publicStorage !== "granted") {
        throw new Error("Permissions not granted");
      }
    }
  };

  const requestPermissions = async () => {
    // This code checks for permissions and requests them if necessary
    try {
      const status = await Filesystem.checkPermissions();
      console.log("Permission check:", status);

      if (status.publicStorage !== "granted") {
        const requestStatus = await Filesystem.requestPermissions();
        console.log("Permission request:", requestStatus);

        if (requestStatus.publicStorage !== "granted") {
          throw new Error("Storage permissions not granted");
        }
      }
    } catch (error) {
      console.error("Error checking or requesting permissions:", error);
      throw error;
    }
  };

  const writeSecretFile = async () => {
    try {
      await Filesystem.requestPermissions();

      let res = await Filesystem.writeFile({
        path: "text.txt",
        data: "This is a test",
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      console.log("File written successfully:", res);

      // Schedule a notification with an action
      await LocalNotifications.schedule({
        notifications: [
          {
            title: "File Downloaded",
            body: "The file text.txt has been successfully downloaded. Click to open.",
            id: 1,
            schedule: { at: new Date(Date.now() + 1000) }, // 1 second delay
            actionTypeId: "OPEN_DIRECTORY",
          },
        ],
      });

      alert("File written and notification scheduled");
    } catch (e) {
      console.error("Unable to write file", e);
      alert("Error writing file: " + JSON.stringify(e));
    }
  };

  // const generateExcel = async () => {
  //   alert(JSON.stringify(Filesystem.requestPermissions()))
  //   // Create a new workbook
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet('Sheet 1');

  //   // Add some data
  //   worksheet.columns = [
  //     { header: 'ID', key: 'id', width: 10 },
  //     { header: 'Name', key: 'name', width: 30 },
  //     { header: 'DOB', key: 'dob', width: 10 }
  //   ];

  //   worksheet.addRow({ id: 1, name: 'John Doe', dob: new Date(1990, 1, 1) });
  //   worksheet.addRow({ id: 2, name: 'Jane Doe', dob: new Date(1995, 5, 5) });

  //   // Write to buffer
  //   const buffer = await workbook.xlsx.writeBuffer();

  //   if (Capacitor.isNativePlatform()) {
  //     // Convert buffer to base64 string
  //     const uint8Array = new Uint8Array(buffer);
  //     const binaryString = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
  //     const base64String = btoa(binaryString);

  //     // Save the file using Capacitor Filesystem
  //     const result = await Filesystem.writeFile({
  //       path: 'example.xlsx',
  //       data: base64String,
  //       directory: Directory.Documents,
  //       encoding: Encoding.Base64,
  //     });

  //     alert('Excel file created successfully!');
  //     console.log('File saved at:', result.uri);
  //   } else {
  //     // For web, use FileSaver to trigger the download
  //     const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'example.xlsx';
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //     alert('Excel file created successfully!');
  //   }
  // };

  LocalNotifications.addListener(
    "localNotificationActionPerformed",
    async (notification) => {
      if (notification.notification.actionTypeId === "OPEN_FILE") {
        try {
          let result = await Filesystem.readFile({
            path: "text.txt",
            directory: Directory.Documents,
            encoding: Encoding.UTF8,
          });

          // Display the file content
          alert("File content: " + result.data);
        } catch (e) {
          console.error("Unable to read file", e);
        }
      }
    }
  );

  const saveAsExcelFile = async (buffer, fileName) => {
    if (Capacitor.isNativePlatform()) {
      // Convert buffer to base64
      const base64Data = buffer.toString("base64");

      // Save the file using Capacitor Filesystem
      const result = await Filesystem.writeFile({
        path: "example.xlsx",
        data: base64Data,
        directory: Directory.Documents,
        encoding: Encoding.Base64,
      });

      await LocalNotifications.schedule({
        notifications: [
          {
            title: "File Downloaded",
            body: "The file text.txt has been successfully downloaded.",
            id: 1,
            schedule: { at: new Date(Date.now() + 1000) }, // 1 second delay
            actionTypeId: "OPEN_FILE",
          },
        ],
      });

      alert("Excel file created successfully!");
      alert(JSON.stringify(result.uri));
    } else {
      import("file-saver").then((module) => {
        if (module && module.default) {
          let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          let EXCEL_EXTENSION = ".xlsx";
          const data = new Blob([buffer], {
            type: EXCEL_TYPE,
          });

          module.default.saveAs(
            data,
            fileName + "export" + new Date().getTime() + EXCEL_EXTENSION
          );
        }
      });
    }
  };
  return (
    <div>
      <div style={{ height: height, width: width, overflowY: "hidden" }}>
        <div
          style={{
            height: 60,
            width: width,
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div
            style={{
              width: 60,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "gray",
            }}
            onClick={() => window.history.back()}
            // onClick={() => (window.location = "/reports")}
          >
            <MdArrowBackIos fontSize={25} />
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            GST Reports
          </div>
          <div
            style={{
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Badge
              onClick={() => setModal(true)}
              style={{
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                background: "white",
              }}
              value={<IoMdMore size={25} color="var(--primary-color)" />}
            />
          </div>
        </div>
        <div
          style={{
            height: 75,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 13, color: "gray", fontWeight: "bold" }}>
            Net Amount
          </div>
          <div
            style={{
              fontSize: 25,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {invoiceTotal.net_amount}
          </div>
        </div>
        <div style={{ height: 60, display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              flex: 1,

              borderRightStyle: "solid",
              borderRightWidth: 0.5,
            }}
          >
            <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
              Total Bills
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {invoiceData.length}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              flex: 1,
            }}
          >
            <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
              Total GST
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {invoiceTotal.gst_total}
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              width: "100%",
              height: 70,
              display: "flex",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
            }}
            onClick={exportExcel}
            // onClick={writeSecretFile}
          >
            <div
              style={{
                width: 70,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 45,
                  width: 45,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 100,
                  background: "var(--cyan-400)",
                  color: "white",
                }}
              >
                <CgCopy />
              </div>
            </div>
            <div
              style={{
                height: "100%",
                flex: 1,
                display: "flex",
                fontSize: 18,
                fontWeight: "bold",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <div>Gst Report</div>
              <div
                style={{ fontSize: 14, color: "gray", fontWeight: "normal" }}
              >
                Download Report XLS
              </div>
            </div>
            <div
              style={{
                width: 70,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "gray",
              }}
            >
              <MdArrowForwardIos fontSize={25} />
            </div>
          </div>
        </div>
      </div>

      <FilterModal
        arrayData={arrayData}
        visible={modal}
        filterDate={filterDate}
        filter={(date, id) => load_gst_report(date, id)}
        closeModal={() => setModal(false)}
      />
    </div>
  );
}
