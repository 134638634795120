import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { MdArrowBackIos, MdDelete } from "react-icons/md";
import Backend from "../Backend/Backend";
import { Card } from "primereact/card";
import SalesReportCard from "./SalesReportCard";
import FilterModal from "./FilterModal";
import moment from "moment";

import { Badge } from "primereact/badge";
import { IoMdMore } from "react-icons/io";

const backend = new Backend();
export default function SalesReports() {
  const [width, height] = useWindowSize();
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [invoiceTotal, setInvoiceTotal] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filterDate, setFilterDate] = React.useState([new Date(), new Date()]);
  const [modal, setModal] = React.useState(false);
  const [filterData, setFilterData] = React.useState([]);
  const [arrayData, setArrayData] = React.useState([]);
  const scrollheight = height - 200;

  React.useEffect(() => {
    load_sales_report();
  }, []);

  const load_sales_report = (date, filter) => {
    if (!loading) {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        filter_id: filter?.admin_id > 0 ? filter?.admin_id : 0,
        from_date: date
          ? moment(date[0]).format("YYYY-MM-DD 00:00:00")
          : new Date(),
        to_date: date
          ? moment(date[1]).format("YYYY-MM-DD 23:59:59")
          : new Date(),
      };

      if (localStorage.getItem("superAdmin") == "1") {
        backend.load_sales_report_sa(data).then((r) => {
          if (r.error == "False") {
            setInvoiceData(r.invoices);
            setInvoiceTotal(r.total);
            setArrayData(r.outlet);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      } else {
        backend.load_sales_report(data).then((r) => {
          // alert(JSON.stringify(r.sql));
          if (r.error == "False") {
            setInvoiceData(r.invoices);
            setInvoiceTotal(r.total);
            setArrayData(r.captain);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const delete_invoice = (id) => {
    if (id <= 0) {
      window.alert("Invalid Invoice Id. Please try again after reload!");
    }
    if (window.confirm("Do you really want to delete this invoice?")) {
      if (!loading) {
        setLoading(true);
        let data = {
          authtoken: localStorage.getItem("admin_token"),
          admin_id: localStorage.getItem("admin_id"),
          invoice_id: id,
        };
        backend.delete_invoice(data).then((r) => {
          if (r.error == "False") {
            load_sales_report();
            window.alert(r.message);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const delete_all_invoice = (id) => {
    const passwords = window.prompt("ENTER PASSWORD TO CLEAR DATA");
    if (passwords) {
      if (!loading) {
        setLoading(true);
        let data = {
          authtoken: localStorage.getItem("admin_token"),
          admin_id: localStorage.getItem("admin_id"),
          passwords: passwords,
        };
        backend.delete_all_invoice(data).then((r) => {
          if (r.error == "False") {
            load_sales_report();
            window.alert(r.message);
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        });
      }
    }
  };

  return (
    <>
      <div style={{ height: height, width: width, overflowY: "hidden" }}>
        <div
          style={{
            height: 60,
            width: width,
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div
            style={{
              width: 60,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "gray",
            }}
            onClick={() => window.history.back()}
            // onClick={() => (window.location = "/reports")}
          >
            <MdArrowBackIos fontSize={25} />
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            Sales Reports
          </div>
          <div
            style={{
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Badge
              onClick={() => setModal(true)}
              style={{
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                background: "white",
              }}
              value={<IoMdMore size={25} color="var(--primary-color)" />}
            />
          </div>
        </div>
        <div
          style={{
            height: 75,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 13, color: "gray", fontWeight: "bold" }}>
            Net Amount
          </div>
          <div
            style={{
              fontSize: 25,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {invoiceTotal.net_amount}
          </div>
        </div>
        <div style={{ height: 60, display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              flex: 1,

              borderRightStyle: "solid",
              borderRightWidth: 0.5,
            }}
          >
            <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
              Total Bills
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {invoiceData.length}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderBottomStyle: "solid",
              borderBottomWidth: 0.5,
              flex: 1,
            }}
          >
            <div style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
              Total
            </div>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              {invoiceTotal.total}
            </div>
          </div>
        </div>

        {/* <div>today net amount {invoiceTotal.net_amount}</div> */}
        {/* <div>today total {invoiceTotal.total}</div> SALE REPORT GOES headers */}

        <div
          style={{
            height: scrollheight,

            overflowY: "scroll",
          }}
        >
          {invoiceData.map((i, t) => (
            <SalesReportCard
              delete_invoice={() => delete_invoice(i.id)}
              data={i}
              key={i.id}
            />
          ))}
        </div>
      </div>
      <FilterModal
        arrayData={arrayData}
        visible={modal}
        filterDate={filterDate}
        filter={(date, id) => load_sales_report(date, id)}
        closeModal={() => setModal(false)}
      />
    </>
  );
}
