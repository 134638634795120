// BarcodeScanner.js
import React, { useEffect, useState } from "react";

function BarcodeScanner({ onBarcodeScanned }) {
  const [barcode, setBarcode] = useState("");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        return false;
      }
      // Check if the pressed key is Enter
      if (event.key === "Enter") {
        // Prevent the default behavior (e.g., form submission)
        event.preventDefault();
        // Call the provided callback with the scanned barcode
        if (barcode) {
          onBarcodeScanned(barcode);
          // Clear the barcode state for the next scan
          setBarcode("");
        }
      } else {
        // Append the pressed key to the barcode state
        setBarcode((prevBarcode) => prevBarcode + event.key);
      }
    };

    // Add event listener to capture keydown events on the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode, onBarcodeScanned]);

  // Return null as we don't need to render any visible elements
  return null;
}

export default BarcodeScanner;
