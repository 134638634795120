import React from "react";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  addToCart,
  decreaseCartItem,
  removeFromCart,
  updateCartQty,
} from "../Redux/Reducer";
import { Dialog } from "primereact/dialog";
import { useWindowSize } from "@react-hook/window-size";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";

export default function SidebarCard(props) {
  const { data } = props;
  const [visible, setVisible] = React.useState(false);
  const [qtyvalue, setQtyValue] = React.useState("");
  const [width, height] = useWindowSize();
  const width80 = (width * 80) / 100;
  const dispatch = useDispatch(); // Initialize useDispatch hook

  const handleAddToCart = () => {
    dispatch(addToCart(data)); // Dispatch ADD_TO_CART action with product data
  };
  const setQtyReducer = () => {
    // alert(JSON.stringify(data));
    // return false;
    if (parseFloat(qtyvalue) > 0) {
      dispatch(updateCartQty(data.id, data.price, qtyvalue));
      setVisible(false);
    }
  };

  const handleDecreaseCartItem = () => {
    dispatch(decreaseCartItem(data.id, data.price)); // Dispatch DECREASE_CART_ITEM action with product ID
  };

  const handleRemoveFromCart = () => {
    dispatch(removeFromCart(data.id, data.price)); // Dispatch REMOVE_FROM_CART action with product ID
  };
  const handleqtyedit = () => {
    // const data = prompt("SHOW QTY");
    // alert(data);
    setVisible(true);
  };

  return (
    <div
      style={{
        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
        display: "flex",
      }}
    >
      <Dialog
        visible={visible}
        modal
        onHide={() => setVisible(false)}
        content={({ hide }) => (
          <div
            style={{
              width: width80,
              background: "white",
              borderRadius: 4,
              padding: 10,
            }}
          >
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                color: "var(--primary-color)",
              }}
            >
              {data.product_name}
            </div>
            <div style={{ width: "100%", marginTop: 10 }}>
              <InputText
                onChange={(e) => setQtyValue(e.target.value)}
                placeholder="ENTER QTY"
                type="number"
                style={{ width: "100%" }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: 20,
              }}
            >
              <Badge
                // onClick={() =>
                //   navigation("/editProduct", { state: { data: data } })
                // }
                onClick={() => setVisible(false)}
                value={"CANCLE"}
                severity={"danger"}
                style={{ borderRadius: 3 }}
              ></Badge>
              <Badge
                onClick={setQtyReducer}
                // onClick={() =>
                //   navigation("/editProduct", { state: { data: data } })
                // }
                value={"SAVE"}
                style={{ borderRadius: 3 }}
              ></Badge>
            </div>
          </div>
        )}
      ></Dialog>
      <div
        style={{
          width: 35,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ height: 25, width: 25 }}>
          <div
            style={{
              height: "100%",
              background: "var(--red-600)",
              width: "100%",
              borderRadius: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
            onClick={handleRemoveFromCart}
          >
            <FaTrash fontSize={12} />
          </div>
        </div>
      </div>
      <div
        onClick={handleqtyedit}
        style={{ flex: 1, padding: 5, paddingLeft: 10 }}
      >
        <div style={{ fontWeight: "bold", fontSize: 14 }}>
          {data.product_name}
        </div>
        <div style={{ fontWeight: "bold", fontSize: 12, color: "gray" }}>
          {parseFloat(data.price)} x {data.qty} =
          {parseFloat(data.amount).toFixed(2)}
        </div>
      </div>
      <div
        style={{
          width: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div style={{ height: 25, width: 25 }}>
          <div
            style={{
              height: "100%",
              background: "var(--orange-400)",
              width: "100%",
              borderRadius: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
            onClick={handleDecreaseCartItem}
          >
            <FaMinus fontSize={14} />
          </div>
        </div>
        <div style={{ height: 25, width: 25 }}>
          <div
            style={{
              height: "100%",
              background: "var(--green-400)",
              width: "100%",
              borderRadius: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
            onClick={handleAddToCart}
          >
            <FaPlus fontSize={14} />
          </div>
        </div>
      </div>
    </div>
  );
}
