import { InputText } from "primereact/inputtext";
import React from "react";
import Backend from "../Backend/Backend";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const backend = new Backend();

export default function ExpenseModal({ visible, closeModal }) {
  const [amount, setAmount] = React.useState("");
  const [details, setDetails] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const add_expense = () => {
    if (amount <= 0) {
      window.alert("Enter amount greater than 0");
    } else if (details == "" || details == undefined || details == null) {
      window.alert("Enter details for expense");
    } else {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        amount: amount,
        details: details,
      };

      backend.add_expense(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setAmount("");
          setDetails("");
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={() => add_expense()}
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header=" Expense"
      visible={visible}
      style={{ width: "90%" }}
      onHide={() => closeModal(false)}
      position={"bottom"}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Amount
        </div>
        <InputText
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Details
        </div>
        <InputText
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
    </Dialog>
  );
}
