import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Backend from "../Backend/Backend";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";

const backend = new Backend();

export default function Register() {
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    mobile: "",
    password: "",
    confirm_password: "",
    company_name: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    state: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const register = () => {
    if (
      formData.name.trim() === "" ||
      formData.password.trim() === "" ||
      formData.confirm_password.trim() === "" ||
      formData.company_name.trim() === "" ||
      formData.address_line_1.trim() === "" ||
      formData.address_line_2.trim() === "" ||
      formData.address_line_3.trim() === "" ||
      formData.state.trim() === "" ||
      formData.city.trim() === ""
    ) {
      alert("Please fill in all the required fields correctly");
    } else if (formData.mobile.length !== 10) {
      alert("Enter a mobile number of 10 digits");
    } else if (formData.password !== formData.confirm_password) {
      alert("Password does not match");
    } else {
      setLoading(true);
      let data = {};
      Object.keys(formData).forEach((field) => {
        data[field] = formData[field];
      });

      backend.register(data).then((r) => {
        if (r.error == "False") {
          window.alert(r.message);
          toLogin();
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };

  const toLogin = () => {
    navigation("/login");
  };

  return (
    <div style={{ height: height }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Signup
        </div>
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        ></div>
      </div>
      {Object.keys(formData).map((fieldName) => (
        <div
          key={fieldName}
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              color: "var(--primary-color)",

              fontSize: 18,
            }}
          >
            {fieldName && (
              <span>{capitalizeFirstLetter(fieldName.replace(/_/g, " "))}</span>
            )}
          </div>
          <InputText
            onChange={handleChange}
            type="text"
            id={fieldName}
            name={fieldName}
            placeholder={
              "Enter " + capitalizeFirstLetter(fieldName.replace(/_/g, " "))
            }
            value={formData[fieldName]}
            style={{ marginTop: 5, width: "100%" }}
          />
        </div>
        // <div key={fieldName}>
        //   <div
        //     style={{
        //       color: "var(--primary-color)",
        //       fontSize: 18,
        //     }}
        //   >
        //     {fieldName}
        //   </div>
        //   <InputText
        //     onChange={handleChange}
        //     type="text"
        //     id={fieldName}
        //     name={fieldName}
        //     value={formData[fieldName]}
        //     style={{ marginTop: 5, width: "100%" }}
        //   />
        // </div>
      ))}

      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          label={loading ? "LOADING..." : "SIGNUP"}
          // onClick={() => !loading && register()}
          onClick={() => register()}
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
      <div style={{ height: 50 }}></div>
    </div>
  );
}
