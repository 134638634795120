import React from "react";
import { Sidebar } from "primereact/sidebar";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { FaCashRegister } from "react-icons/fa";
import { MdHome } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import SidebarCard from "./SidebarCard";
import { useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import ExpenseModal from "./ExpenseModal";
import Backend from "../Backend/Backend";
import DiscountModal from "./DiscountModal";
import { useNavigate } from "react-router-dom";

const backend = new Backend();

export default function SidebarPos({
  status,
  close,
  expenseTotal,
  invoiceTotal,
  setDiscount,
}) {
  const navigation = useNavigate();
  const [openExpense, setOpenExpense] = React.useState(false);
  const [openDiscount, setOpenDiscount] = React.useState(false);

  const [workerName, setWorkerName] = React.useState(
    JSON.parse(localStorage.getItem("admin_data")).name
  );
  var cart = useSelector((state) => state.cartItems);

  const totalAmount = cart.reduce((total, item) => total + item.amount, 0);

  const [loading, setLoading] = React.useState(false);

  return (
    <Sidebar
      className="sidebar-header"
      visible={status}
      showCloseIcon={false}
      onHide={close}
      style={{ padding: 0, margin: "0", height: "100%" }}
    >
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            height: 90,
            background: "var(--primary-color)",
            display: "flex",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              background: "white",
              top: 20,
              right: 0,
              width: 50,
              height: 50,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "var(--primary-color)",
            }}
            onClick={close}
          >
            <FaCircleArrowLeft fontSize={25} style={{ marginRight: -10 }} />
          </div>
          <div
            style={{
              height: "100%",
              width: 80,
              display: "flex",

              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: 50,
                width: 50,
                borderRadius: 100,
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                fontWeight: "bolder",
                color: "var(--primary-color)",
              }}
            >
              {workerName[0]?.toUpperCase()}
            </div>
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                flex: 1,
                color: "white",
                fontWeight: "bold",
                fontSize: 16,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>{workerName?.toUpperCase()}</div>
              <div style={{ fontSize: 14, fontWeight: "normal" }}>
                Total : ₹{parseFloat(invoiceTotal)}
              </div>

              <div style={{ fontSize: 14, fontWeight: "normal" }}>
                Exp : ₹{parseFloat(expenseTotal)}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1, overflowY: "scroll" }}>
          <div>
            {cart.length > 0
              ? cart.map((i, t) => <SidebarCard key={i.id} data={i} />)
              : null}
          </div>
        </div>

        <div
          style={{
            height: 60,
            display: "flex",
            borderTopStyle: "solid",
            borderTopWidth: 1,
            borderTopColor: "var(--primary-color)",
            color: "var(--primary-color)",
          }}
        >
          <div
            style={{
              flex: 1,
              padding: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRightStyle: "solid",

              borderRightColor: "var(--primary-color)",
            }}
            onClick={() =>
              totalAmount > 0
                ? setOpenDiscount(true)
                : window.alert("Cart is Empty")
            }
          >
            <div style={{ fontSize: 20, fontWeight: "bold" }}>%</div>
            <div style={{ fontSize: 11, fontWeight: "bold", color: "black" }}>
              Discount{" "}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              padding: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRightStyle: "solid",
              borderRightColor: "var(--primary-color)",
            }}
            onClick={() => setOpenExpense(true)}
          >
            <div style={{ fontSize: 20, fontWeight: "bold" }}>₹</div>
            <div style={{ fontSize: 11, fontWeight: "bold", color: "black" }}>
              Expense{" "}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              padding: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRightStyle: "solid",
              borderRightColor: "var(--primary-color)",
            }}
            onClick={() => navigation("/setting")}
          >
            <div>
              <IoMdSettings size={20} />
            </div>
            <div style={{ fontSize: 11, fontWeight: "bold", color: "black" }}>
              Setting{" "}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              padding: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            onClick={() => window.history.back()}
          >
            <div>
              <MdHome size={20} />
            </div>
            <div style={{ fontSize: 11, fontWeight: "bold", color: "black" }}>
              Home
            </div>
          </div>
        </div>
      </div>

      <ExpenseModal
        visible={openExpense}
        closeModal={() => setOpenExpense(false)}
      />

      <DiscountModal
        visible={openDiscount}
        totalAmount={totalAmount}
        setDiscount={(amount) => setDiscount(amount)}
        closeModal={() => setOpenDiscount(false)}
      />
    </Sidebar>
  );
}
