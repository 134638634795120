import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";
import { Dropdown } from "primereact/dropdown";
import { useLocation } from "react-router-dom";

const backend = new Backend();
export default function EditCategory() {
  const [width, height] = useWindowSize();
  const location = useLocation();
  const data = location.state.data;

  const [categoryName, setCategoryName] = React.useState(data?.category_name);
  const [loading, setLoading] = React.useState(false);

  const [status, setStatus] = React.useState(data?.status == 1 ? true : false);

  const edit_category = () => {
    if (categoryName == "" || categoryName.length < 1) {
      window.alert("Enter Product Name Properly");
    } else {
      setLoading(true);
      let dataSend = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        category_name: categoryName,
        status: status == true ? 1 : 0,
        category_id: data?.id,
      };
      backend.edit_category(dataSend).then((r) => {
        if (r.error == "False") {
          window.alert(r.message);
          window.history.back();
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };

  const delete_category = () => {
    if (data?.id <= 0) {
      window.alert("Invalid product. please reload");
    } else {
      if (window.confirm("Do you really want to delete this category?")) {
        if (!loading) {
          setLoading(true);
          let dataSend = {
            authtoken: localStorage.getItem("admin_token"),
            admin_id: localStorage.getItem("admin_id"),
            category_id: data?.id,
          };
          backend.delete_category(dataSend).then((r) => {
            if (r.error == "False") {
              window.history.back();
              window.alert(r.message);
            } else {
              window.alert(r.message);
            }
            setLoading(false);
          });
        }
      }
    }
  };
  return (
    <div style={{ height: height, width: width }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
          marginBottom: 10,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Edit Category
        </div>
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        ></div>
        <div
          style={{
            padding: 10,
            paddingTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={() => delete_category()}
            label="Delete"
            severity="danger"
            style={{ marginTop: 10, width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Category Name
        </div>
        <InputText
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Status
        </div>
        <InputSwitch checked={status} onChange={() => setStatus(!status)} />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => edit_category()}
          label="Update"
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
    </div>
  );
}
