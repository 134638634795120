import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";

const backend = new Backend();

export default function AddPaymentMethod(props) {
  const [width, height] = useWindowSize();

  const [paymentName, setPaymentName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const add_payment_method = () => {
    if (paymentName == "" || paymentName.length < 1) {
      window.alert("Enter Payment Name Properly");
    } else {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        payment_name: paymentName,
      };
      backend.add_payment_method(data).then((r) => {
        if (r.error == "False") {
          window.history.back();
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };

  return (
    <div style={{ height: height, width: width }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          //   borderBottomStyle: "solid",
          //   borderBottomWidth: 0.5,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Add Payment Method
        </div>
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        ></div>
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Payment Name
        </div>
        <InputText
          onChange={(e) => setPaymentName(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => add_payment_method()}
          label="Add Payment"
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
    </div>
  );
}
