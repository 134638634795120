import { InputText } from "primereact/inputtext";
import React from "react";
import Backend from "../Backend/Backend";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const backend = new Backend();

export default function PremiumModal({ visible, closeModal }) {
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
      />
      <Button
        label="Call"
        icon="pi pi-check"
        onClick={() => (window.location.href = "tel:+918128980289")}
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header="Go Premium"
      visible={visible}
      style={{ width: "90%" }}
      onHide={() => closeModal(false)}
      position={"bottom"}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Get premuim to active this feature. Contact us now.
        </div>
      </div>
    </Dialog>
  );
}
