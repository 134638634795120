import React from "react";
import RING from "./ring.png";
import { MdLocalMall, MdArrowForwardIos } from "react-icons/md";

export default function MenuButton({
  colorb = "var(--primary-color)",
  name,
  desc,
  Icon,
  route,
}) {
  return (
    <div
      style={{
        width: "100%",
        height: 70,
        display: "flex",
        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
      }}
      onClick={() => route()}
    >
      <div
        style={{
          width: 70,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 45,
            width: 45,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 100,
            background: colorb,
            color: "white",
          }}
        >
          <Icon />
        </div>
      </div>
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          fontSize: 18,
          fontWeight: "bold",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <div>{name}</div>
        <div style={{ fontSize: 14, color: "gray", fontWeight: "normal" }}>
          {desc}
        </div>
      </div>
      <div
        style={{
          width: 70,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "gray",
        }}
      >
        <MdArrowForwardIos fontSize={25} />
      </div>
    </div>
  );
}
