import React from "react";

export default function CustomerCard({ data, handletouchcustomer }) {
  return (
    <div
      style={{
        padding: 5,
        background: "var(--primary-50)",
        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
      }}
      onClick={handletouchcustomer}
    >
      <div style={{ fontWeight: "bold" }}>{data.customer_name}</div>
      <div>{data.customer_mobile}</div>
    </div>
  );
}
