import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";
import { Dropdown } from "primereact/dropdown";

const backend = new Backend();
export default function AddCaptain(props) {
  const [width, height] = useWindowSize();

  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const add_captain = () => {
    if (name == "" || name.length < 1) {
      window.alert("Enter Captain Name Properly");
    } else if (password == "" || password.length < 3) {
      window.alert("Enter Password atleast 3 Character");
    } else if (mobile == "" || mobile.length != 10) {
      window.alert("Enter Mobile Number properly with 10 digit");
    } else {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        captain_name: name,
        password: password,
        mobile: mobile,
      };
      backend
        .add_captain(data)
        .then((r) => {
          if (r.error == "False") {
            window.alert(r.message);
            window.history.back();
          } else {
            window.alert(r.message);
          }
          setLoading(false);
        })
        .catch((err) => alert(err));
    }
  };

  return (
    <div style={{ height: height, width: width }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          //   borderBottomStyle: "solid",
          //   borderBottomWidth: 0.5,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Add Captain
        </div>
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        ></div>
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Captain Mobile
        </div>
        <InputText
          type="number"
          onChange={(e) => setMobile(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Captain Name
        </div>
        <InputText
          onChange={(e) => setName(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Password
        </div>
        <InputText
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => add_captain()}
          label="Add Captain"
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
    </div>
  );
}
