import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import SaleCard from "../Home/SaleCard";
import MenuButton from "../Home/MenuButton";
import {
  MdDashboardCustomize,
  MdLocalMall,
  MdPayments,
  MdPointOfSale,
  MdPowerSettingsNew,
  MdSettings,
} from "react-icons/md";
import { FaUserGroup } from "react-icons/fa6";
import { HiMiniRectangleGroup } from "react-icons/hi2";
import Backend from "../Backend/Backend";
import { IoMdRefresh, IoMdSync } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { RiProfileLine } from "react-icons/ri";
import PremiumModal from "../Home/PremiumModal";
import moment from "moment";
import Reportsuperadmin from "./Reportsuperadmin";

const backend = new Backend();

export default function HomeSuperadmin() {
  // alert(localStorage.getItem("superAdmin"));
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [dashboardData, setDashboardData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openPremiumModal, setOpenPremiumModal] = React.useState(false);
  const scrollHeight = height - 310;

  React.useEffect(() => {
    dashboard();
  }, []);

  const dashboard = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };

    backend
      .dashboard_super_admin(data)
      .then((r) => {
        if (r.error == "False") {
          setDashboardData(r.admin_data);
        } else {
          window.alert(r.message);
          localStorage.clear();
          window.location.reload();
        }
        setLoading(false);
      })
      .catch((err) => alert(err));
  };

  const handleLogout = () => {
    if (window.confirm("Do you really want to Logout?")) {
      localStorage.clear();
      window.location.reload();
    }
  };

  return (
    <div style={{ height: height, width: width, overflow: "hidden" }}>
      <div
        style={{
          height: 50,
          padding: 10,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="pcolor"
          style={{
            flex: 1,
            textAlign: "left",
            alignSelf: "center",
            fontSize: 22,
            fontWeight: "bold",
          }}
        >
          Simply Sales
        </div>
        <div style={{ width: 70, alignSelf: "center" }}>
          <div
            style={{
              height: 45,
              // width: 45,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              borderRadius: 100,
              color: "black",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => dashboard()}
            >
              <IoMdRefresh fontSize={25} />
              <div style={{ fontSize: 12 }}>Reload</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleLogout()}
            >
              <MdPowerSettingsNew fontSize={25} />
              <div style={{ fontSize: 12 }}>Logout</div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: 50,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="bgp"
          style={{
            flex: 1,
            textAlign: "left",
            alignSelf: "center",
            fontSize: 20,
            fontWeight: "bold",
            padding: 10,
            color: "white",
          }}
        >
          {" "}
          Welcome {dashboardData?.company_name}!
        </div>
      </div>
      <div
        style={{
          height: 180,
          overflowX: "scroll",
          overflowY: "hidden",
          // width: width,
          padding: 20,

          display: "flex",
          // background: "black",
          paddingRight: 0,
        }}
      >
        <SaleCard
          today={dashboardData.nos_outlet}
          name="No. of Outlets"
          // name2=""
          // yesterday={moment(dashboardData.expiry_date).format("DD-MMM-YY")}
          colorb="var(--primary-color)"
        />
        <SaleCard
          name="TODAY"
          name2="YESTERDAY"
          today={dashboardData?.today_total + " Rs"}
          yesterday={dashboardData?.yesterday_total + " Rs"}
          colorb="var(--primary-color)"
        />
        <SaleCard
          name="TODAY"
          name2="YESTERDAY"
          today={dashboardData?.today_bills + " Bills"}
          yesterday={dashboardData?.yesterday_bills + " Bills"}
          colorb="var(--primary-color)"
        />
      </div>

      <div
        style={{
          width: "100%",
          marginTop: 20,
          borderTopStyle: "solid",
          borderTopWidth: 0.5,
          height: scrollHeight,
          overflowY: "scroll",
        }}
      >
        <MenuButton
          Icon={() => <MdDashboardCustomize fontSize={25} />}
          colorb="var(--pink-400)"
          name="Reports"
          desc="Bill/Item Wise Reports"
          route={() => navigation("/Reportsuperadmin")}
        />
      </div>

      <PremiumModal
        closeModal={() => setOpenPremiumModal(false)}
        visible={openPremiumModal}
      />
    </div>
  );
}
