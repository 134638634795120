import { useWindowSize } from "@react-hook/window-size";
import React, { useState } from "react";
import { Badge } from "primereact/badge";
import { useDispatch } from "react-redux";
import { addToCart } from "../Redux/Reducer";
import { useSelector } from "react-redux";
import { CheckExistance } from "../Redux/Carthelper";
import { BottomSheet } from "react-spring-bottom-sheet";
import VariableRate from "./VariableRate";

const POSCard = React.memo(({ data }) => {
  const dispatch = useDispatch(); // Get the dispatch function

  const [width, height] = useWindowSize();
  const newwidth = width / 3 - 5;

  const [openVariableModal, setOpenVariableModal] = useState(false);

  const cart = useSelector((state) => state.cartItems);
  const handleAddToCart = () => {
    dispatch(addToCart({ ...data, qty: 1 }));
  };
  const addToVariableCart = (price) => {
    dispatch(addToCart({ ...data, price, qty: 1 }));
  };
  var cartdata = CheckExistance(data, cart);

  return (
    <div style={{ width: newwidth, minHeight: 75, padding: 3 }}>
      <div
        key={data.id} // Assuming data has a unique id
        className="animate__animated animate__bounceIn"
        onClick={() =>
          data.variable_price == 1
            ? setOpenVariableModal(true)
            : handleAddToCart()
        }
        style={{
          borderWidth: 1,
          borderLeftWidth: 1,
          borderStyle: "solid",
          width: "100%",
          height: "100%",
          borderRadius: 5,
          borderColor: "gray",
          display: "flex",
          padding: 5,
          flexDirection: "column",
          position: "relative",
          background: cartdata.qty > 0 ? "var(--primary-100)" : "unset",
        }}
      >
        <div
          className="pcolor"
          style={{
            textAlign: "center",
            fontSize: 14,
            fontWeight: "bold",
            color: cartdata.qty > 0 ? "black" : "var(--primary-color)",
          }}
        >
          {data.unicode_name ? data.unicode_name : data.product_name}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: 12,
            fontWeight: "bold",
            position: "absolute",
            bottom: 5,
          }}
        >
          {parseFloat(data.price)}₹
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: 12,
            fontWeight: "bold",
            position: "absolute",
            bottom: 5,
            right: 5,
          }}
        >
          {cartdata.qty > 0 ? (
            <div
              style={{
                // background: "var(--red-400)",
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 3,
                fontSize: 11,
                color: "white",
                fontWeight: "bold",
                background: "var(--red-400)",
              }}
            >
              {parseFloat(cartdata.qty)}
            </div>
          ) : // <Badge
          //   style={{
          //     fontSize: 10,
          //     padding: 0,
          //     height: 20,
          //     borderRadius: 5,
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //   }}
          //   value={parseFloat(cartdata.qty)}
          //   severity="info"
          // ></Badge>
          null}
        </div>
      </div>
      <VariableRate
        closeModal={() => {
          setOpenVariableModal(false);
        }}
        rate={data?.price}
        visible={openVariableModal}
        addToVariableCart={(price) => addToVariableCart(price)}
      />
    </div>
  );
});
export default POSCard;
