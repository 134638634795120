// cartReducer.js

// Define initial state
const initialState = {
  cartItems: [],
};

// Define action types
const ADD_TO_CART = "ADD_TO_CART";
const REMOVE_FROM_CART = "REMOVE_FROM_CART";
const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
const DECREASE_CART_ITEM = "DECREASE_CART_ITEM";
const CLEAR_CART = "CLEAR_CART";
const UPDATE_CART_QTY = "UPDATE_CART_QTY";

// Define action creators
export const addToCart = (product) => ({
  type: ADD_TO_CART,
  payload: product,
});

export const removeFromCart = (productId, rate) => ({
  type: REMOVE_FROM_CART,
  payload: {
    productId,
    rate,
  },
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const updateCartItem = (productId, qty) => ({
  type: UPDATE_CART_ITEM,
  payload: {
    productId,
    qty,
  },
});

export const decreaseCartItem = (productId, rate) => ({
  type: DECREASE_CART_ITEM,
  payload: {
    productId,
    rate,
  },
});

export const updateCartQty = (productId, rate, qty) => ({
  type: UPDATE_CART_QTY,
  payload: {
    productId,
    rate,
    qty,
  },
});

// Define the cart reducer
const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const existingProductIndex = state.cartItems.findIndex(
        (item) =>
          item.id === action.payload.id && item.price === action.payload.price
      );
      
      if (existingProductIndex !== -1) {
        // If exists with the same id and price, update the quantity for the existing item
        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.id === action.payload.id && item.price === action.payload.price
              ? {
                  ...item,
                  qty: parseFloat(item.qty) + 1,
                  amount: item.price * (parseFloat(item.qty) + 1), // Corrected calculation
                  gstAmount: (item.price * (parseFloat(item.qty) + 1)) - (((item.price * (parseFloat(item.qty) + 1)) / (100 + parseFloat(item.gst) )) * 100),
                }
              : item
          ),
        };
      } else {
        // If not exists or has a different price, add the new product
        return {
          ...state,
          cartItems: [
            ...state.cartItems,
            {
              ...action.payload,
              amount: action.payload.price * 1, // Add amount when adding a new product
              gstAmount: (action.payload.price * 1 ) - (((action.payload.price * 1) / (100 + parseFloat(action.payload.gst))) * 100),
            },
          ],
        };
      }

    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) =>
            item.id !== action.payload.productId ||
            item.price !== action.payload.rate
        ),
      };

    case DECREASE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems
          .map((item) => {
            if (
              item.id === action.payload.productId &&
              item.price === action.payload.rate
            ) {
              const newQty = Math.max(0, item.qty - 1);
              if (newQty === 0) {
                // If new quantity becomes zero, remove the item from the cart
                return null;
              } else {
                return {
                  ...item,
                  qty: newQty,
                  amount: item.price * newQty,
                };
              }
            } else {
              return item;
            }
          })
          .filter(Boolean), // Filter out null items (items with quantity zero)
      };
    case UPDATE_CART_QTY:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.payload.productId &&
          item.price === action.payload.rate
            ? {
                ...item,
                qty: action.payload.qty,
                amount: item.price * action.payload.qty,
              }
            : item
        ),
      };

    default:
      return state;
  }
};

export default Reducer;
