import React from "react";
import { FcComboChart, FcTodoList, FcBearish, FcBarChart } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Header from "../Component/Header";

export default function Reports() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <Header name={"Reports"} />

      <div>
        <div style={styles.reportItem} onClick={() => handleNavigation("/lol")}>
          <div style={styles.iconWrapper}>
            <FcComboChart size={25} />
          </div>
          <div style={styles.text}>Invoice Report</div>
          <div style={styles.arrowIcon}>
            <IoIosArrowForward />
          </div>
        </div>

        <div style={styles.reportItem} onClick={() => handleNavigation("/itemwiseReport")}>
          <div style={styles.iconWrapper}>
            <FcTodoList size={25} />
          </div>
          <div style={styles.text}>Item Wise Report</div>
          <div style={styles.arrowIcon}>
            <IoIosArrowForward />
          </div>
        </div>

        <div style={styles.reportItem} onClick={() => handleNavigation("/expense")}>
          <div style={styles.iconWrapper}>
            <FcBearish size={25} />
          </div>
          <div style={styles.text}>Expense Report</div>
          <div style={styles.arrowIcon}>
            <IoIosArrowForward />
          </div>
        </div>

        <div style={styles.reportItem} onClick={() => handleNavigation("/GSTreport")}>
          <div style={styles.iconWrapper}>
            <FcBarChart size={25} />
          </div>
          <div style={styles.text}>GST Report</div>
          <div style={styles.arrowIcon}>
            <IoIosArrowForward />
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  reportItem: {
    height: 60,
    borderBottomStyle: "solid",
    borderBottomWidth: 0.5,
    display: "flex",
    padding: 5,
    fontSize: 20,
    fontWeight: "bold",
    alignItems: "center",
    cursor: "pointer",
  },
  iconWrapper: {
    width: 50,
    display: "flex",
    justifyContent: "center",
  },
  text: {
    flex: 1,
    textAlign: "left",
  },
  arrowIcon: {
    width: 40,
  },
};
