import { InputText } from "primereact/inputtext";
import React from "react";
import Backend from "../Backend/Backend";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { Badge } from "primereact/badge";

const backend = new Backend();

export default function PaymentModal({
  visible,
  closeModal,
  addPayment,
  paymentMethod,
}) {
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={() => addPayment()}
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header={" Payment Method"}
      visible={visible}
      style={{ width: "90%" }}
      onHide={() => closeModal(false)}
      position={"center"}
      //   footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Badge
          value={"Cash"}
          onClick={() => addPayment("Cash")}
          severity={
            paymentMethod == "Cash" ? "success" : "var(--primary-color)"
          }
          style={{
            borderRadius: 5,
            alignSelf: "center",
            minWidth: "30%",
            fontSize: 16,
            height: 35,
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            backgroundColor:
              paymentMethod !== "Cash"
                ? "var(--primary-color)"
                : "var(--green-500)",
          }}
        ></Badge>

        {JSON.parse(localStorage.getItem("payment"))
          ? JSON.parse(localStorage.getItem("payment")).map((i, t) => (
              <Badge
                onClick={() => addPayment(i.payment_name)}
                value={i.payment_name}
                severity={
                  paymentMethod == i.payment_name
                    ? "success"
                    : "var(--primary-color)"
                }
                style={{
                  borderRadius: 5,
                  alignSelf: "center",
                  minWidth: "30%",
                  fontSize: 16,
                  height: 35,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor:
                    paymentMethod !== i.payment_name
                      ? "var(--primary-color)"
                      : "var(--green-500)",
                }}
              ></Badge>
            ))
          : null}
      </div>
    </Dialog>
  );
}
