// import logo from "./logo.svg";
import "animate.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/fluent-light/theme.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Category from "./Category/Category";
import Home from "./Home/Home";
import Login from "./Login/Login";
import PosMain from "./POS/PosMain";
import Products from "./Prodcuts/Products";
import Register from "./Register/Register";
import AddProducts from "./Prodcuts/AddProducts";
import Setting from "./PrinterSetting/Setting";
import Reports from "./Reports/Reports";
import SalesReports from "./Reports/SalesReports";
import { App as CapacitorApp } from "@capacitor/app";
import { BleClient } from "@capacitor-community/bluetooth-le";
import ExpenseReports from "./Reports/ExpenseReports";
import ItemWiseReport from "./Reports/ItemWiseReport";
import Captains from "./Captains/Captains";
import AddCaptain from "./Captains/AddCaptain";
import EditCaptain from "./Captains/EditCaptain";
import EditProduct from "./Prodcuts/EditProduct";
import EditCategory from "./Category/EditCategory";
import AddCategory from "./Category/AddCategory";
import React, { useEffect } from "react";
import Profile from "./Profile/Profile";
import BillScreen from "./Reports/BillScreen";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import AddPaymentMethod from "./PaymentMethod/AddPaymentMethod";
import EditPaymentMethod from "./PaymentMethod/EditPaymentMethod";
import Home2 from "./SuperAdmin/HomeSuperadmin";
import HomeSuperadmin from "./SuperAdmin/HomeSuperadmin";
import Reportsuperadmin from "./SuperAdmin/Reportsuperadmin";
import ForgotPassword from "./Forgotpassword/Forgotpassword";
import GSTreport from "./Reports/GSTreport";
import { Filesystem } from "@capacitor/filesystem";

BleClient.initialize();

const checkPermissions = async () => {
  try {
    const permissionStatus = await Filesystem.checkPermissions();
    // alert(JSON.stringify(permissionStatus));
    if (permissionStatus.publicStorage !== "granted") {
      // alert("hi");
      const permissionRequest = await Filesystem.requestPermissions();
      if (permissionRequest.publicStorage !== "granted") {
        throw new Error("Permissions not granted");
      }
    }
  } catch (error) {
    alert("Error checking or requesting permissions", error);
  }
};
// checkAndroidPermissions()
// const checkAndroidPermissions = async () => {
//   const { PermissionsAndroid } = await import('react-native');
//   const readGranted = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE);
//   const writeGranted = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE);
//   return readGranted && writeGranted;
// };

function App() {
  const [LOGIN, SETLOGIN] = React.useState(localStorage.getItem("IsLoggedIn"));
  checkPermissions();
  CapacitorApp.addListener(
    "backbutton",
    (ev) => {
      ev.preventDefault();
      window.history.back();
    },
    false
  );

  return (
    <div className="App">
      <PrimeReactProvider>
        <BrowserRouter>
          {LOGIN == "1" && localStorage.getItem("superAdmin") != "1" ? (
            <Routes>
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/" element={<Home />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/paymentMethod" element={<PaymentMethod />} />
              <Route exact path="/addproduct" element={<AddProducts />} />
              <Route exact path="/AddCategory" element={<AddCategory />} />
              <Route exact path="/category" element={<Category />} />
              <Route exact path="/pos" element={<PosMain />} />
              <Route exact path="/setting" element={<Setting />} />
              <Route exact path="/reports" element={<Reports />} />
              <Route exact path="/lol" element={<SalesReports />} />
              <Route exact path="/expense" element={<ExpenseReports />} />
              <Route path="/GstReport" element={<GSTreport />} />
              <Route
                exact
                path="/itemwiseReport"
                element={<ItemWiseReport />}
              />
              <Route exact path="/captain" element={<Captains />} />
              <Route exact path="/addcaptain" element={<AddCaptain />} />
              <Route exact path="/editcaptain" element={<EditCaptain />} />
              <Route exact path="/editproduct" element={<EditProduct />} />
              <Route exact path="/editcategory" element={<EditCategory />} />
              <Route exact path="/Profile" element={<Profile />} />
              <Route exact path="/BillScreen" element={<BillScreen />} />
              <Route
                exact
                path="/addPaymentMethod"
                element={<AddPaymentMethod />}
              />
              <Route
                path="/editPaymentMethod"
                element={<EditPaymentMethod />}
              />
              <Route path="*" element={<Home />} />
            </Routes>
          ) : LOGIN == "1" && localStorage.getItem("superAdmin") == "1" ? (
            <Routes>
              <Route exact path="/home" element={<HomeSuperadmin />} />

              <Route exact path="/BillScreen" element={<BillScreen />} />
              <Route
                exact
                path="/Reportsuperadmin"
                element={<Reportsuperadmin />}
              />
              <Route exact path="/lol" element={<SalesReports />} />
              <Route exact path="/expense" element={<ExpenseReports />} />
              <Route
                exact
                path="/itemwiseReport"
                element={<ItemWiseReport />}
              />
              <Route exact path="/" element={<HomeSuperadmin />} />
              <Route path="*" element={<HomeSuperadmin />} />
            </Routes>
          ) : (
            <Routes>
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/Forgotpassword"
                element={<ForgotPassword />}
              />
              <Route exact path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </BrowserRouter>
      </PrimeReactProvider>
    </div>
  );
}

export default App;
