import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import CategoryCard from "./CategoryCard";
import Backend from "../Backend/Backend";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useNavigate } from "react-router-dom";
import Header from "../Component/Header";
import AddCategory from "./AddCategory";

const backend = new Backend();

export default function Category() {
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [categoryData, setCategoryData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    load_category();
  }, []);

  const load_category = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend.load_category(data).then((r) => {
      if (r.error == "False") {
        setCategoryData(r.category);
      } else {
        window.alert(r.message);
      }
      setLoading(false);
    });
  };

  const AddCategory = () => {
    navigation("/AddCategory");
  };
  return (
    <>
      <div style={{ height: height, width: width }}>
        <Header name="Category" onClick={() => AddCategory()} />
        {categoryData?.map((i, t) => (
          <CategoryCard key={i.id} index={t + 1} data={i} />
        ))}
      </div>
    </>
  );
}
