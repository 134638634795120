import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";

const backend = new Backend();
export default function AddCategory(props) {
  const [width, height] = useWindowSize();

  const [categoryName, setCategoryName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const add_category = () => {
    if (categoryName == "" || categoryName.length < 1) {
      window.alert("Enter Category Name Properly");
    } else {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        category_name: categoryName,
      };
      backend.add_category(data).then((r) => {
        if (r.error == "False") {
          // props.closeSheet();
          window.history.back();
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };

  return (
    <div style={{ height: height, width: width }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Add Category
        </div>
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        ></div>
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Category Name
        </div>
        <InputText
          onChange={(e) => setCategoryName(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => add_category()}
          label="Add Category"
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
    </div>
  );
}
