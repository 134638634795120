import { InputText } from "primereact/inputtext";
import React from "react";
import Backend from "../Backend/Backend";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";

const backend = new Backend();

export default function DiscountModal({
  visible,
  closeModal,
  setDiscount,
  totalAmount,
}) {
  const [amount, setAmount] = React.useState("");

  const add_discount = () => {
    if (amount <= 0) {
      window.alert("Enter amount greater than 0");
    } else if (amount == "" || amount == undefined || amount == null) {
      window.alert("Enter amount for discount");
    } else if (amount >= totalAmount) {
      window.alert(
        "Discount cannot be greater than total amount : " + totalAmount
      );
    } else {
      setDiscount(amount);
      closeModal();
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={() => add_discount()}
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header={" Discount"}
      visible={visible}
      style={{ width: "90%" }}
      onHide={() => closeModal(false)}
      position={"bottom"}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Amount
        </div>
        <InputText
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      {/* <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Percentage
        </div>
        <InputText
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div> */}
    </Dialog>
  );
}
