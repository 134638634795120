const CheckExistance = (item, cart) => {
  // alert(JSON.stringify(cart));
  let qty = 0;
  if (Array.isArray(cart)) {
    let currentitem = [];
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].id == item.id) {
        qty += cart[i].qty; // return cart[i];
      }
    }
  }
  item.qty = qty;

  return item;
};
export { CheckExistance };
