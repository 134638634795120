import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";
import ExpenseReportCard from "./ExpenseReportCard";
import FilterModal from "./FilterModal";
import { Badge } from "primereact/badge";
import { IoMdMore } from "react-icons/io";
import moment from "moment";

const backend = new Backend();
export default function ExpenseReports() {
  const [width, height] = useWindowSize();
  const [expenseData, setExpenseData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [filterDate, setFilterDate] = React.useState([new Date(), new Date()]);
  const [modal, setModal] = React.useState(false);
  const [filterData, setFilterData] = React.useState([]);
  const [arrayData, setArrayData] = React.useState([]);

  React.useEffect(() => {
    load_expense_report();
  }, []);

  const load_expense_report = (date, filter) => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
      filter_id: filter?.admin_id > 0 ? filter?.admin_id : 0,
      from_date: date
        ? moment(date[0]).format("YYYY-MM-DD 00:00:00")
        : new Date(),
      to_date: date
        ? moment(date[1]).format("YYYY-MM-DD 23:59:59")
        : new Date(),
    };
    if (localStorage.getItem("superAdmin") == "1") {
      backend.load_expense_report_sa(data).then((r) => {
        if (r.error == "False") {
          setExpenseData(r.expense);
          setTotal(r.total);
          setArrayData(r.outlet);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    } else {
      backend.load_expense_report(data).then((r) => {
        if (r.error == "False") {
          setExpenseData(r.expense);
          setTotal(r.total);
          setArrayData(r.captain);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };

  const delete_expense = (id) => {
    if (window.confirm("Do you really want to delete this expense?")) {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        expense_id: id,
      };
      backend.delete_expense(data).then((r) => {
        if (r.error == "False") {
          load_expense_report();
          window.alert(r.message);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };
  return (
    <>
      <div style={{ height: height, width: width }}>
        <div
          style={{
            height: 60,
            width: width,
            display: "flex",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div
            style={{
              width: 60,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "gray",
            }}
            onClick={() => window.history.back()}
            // onClick={() => (window.location = "/reports")}
          >
            <MdArrowBackIos fontSize={25} />
          </div>
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            Expense Reports
          </div>
          <div
            style={{
              width: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Badge
              onClick={() => setModal(true)}
              style={{
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                background: "white",
              }}
              value={<IoMdMore size={25} color="var(--primary-color)" />}
            />
          </div>
        </div>
        <div
          style={{
            height: 75,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
          }}
        >
          <div style={{ fontSize: 13, color: "gray", fontWeight: "bold" }}>
            Net Amount
          </div>
          <div
            style={{
              fontSize: 25,
              fontWeight: "bold",
              color: "var(--primary-color)",
            }}
          >
            {total ? total : 0}
          </div>
        </div>

        <div
          style={{
            padding: 5,
            display: "flex",
            fontWeight: "bold",
            background: "var(--primary-color)",
            color: "white",
          }}
        >
          <div style={{ flex: 4, textAlign: "left" }}>Expense </div>
          <div style={{ flex: 2 }}>Amount</div>
          <div style={{ flex: 1, textAlign: "center" }}></div>
        </div>

        {expenseData.map((i, t) => (
          <ExpenseReportCard
            delete_expense={(id) => delete_expense(id)}
            data={i}
          />
        ))}
      </div>
      <FilterModal
        arrayData={arrayData}
        visible={modal}
        filterDate={filterDate}
        filter={(date, id) => load_expense_report(date, id)}
        closeModal={() => setModal(false)}
      />
    </>
  );
}
