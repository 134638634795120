import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { IonLoading } from "@ionic/react";
import { BeatLoader } from "react-spinners";
import { InputText } from "primereact/inputtext";
import Header from "../Component/Header";
import Backend from "../Backend/Backend";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

const backend = new Backend();
export default function Profile() {
  const [width, height] = useWindowSize();
  const navigation = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [permission, setPermission] = React.useState(0);
  const scrollheigh = height - 70;
  const [formData, setFormData] = React.useState({
    name: "",
    mobile: "",
    display_mobile: "",
    company_name: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    state: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  React.useEffect(() => {
    load_profile();
  }, []);


  const load_profile = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };

    backend
      .load_profile(data)
      .then((r) => {
        setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("admin_data", JSON.stringify(r.admin_data[0]));
          var address = r.admin_data[0].address.split("|,|");
          setFormData({
            ...formData,
            name: r.admin_data[0].name,
            mobile: r.admin_data[0].mobile,
            display_mobile: r.admin_data[0].display_mobile,
            company_name: r.admin_data[0].company_name,
            state: r.admin_data[0].state,
            city: r.admin_data[0].city,
            address_line_1: address[0],
            address_line_2: address[1],
            address_line_3: address[2],
          });
          setGSTNUMBER(r.admin_data[0].gst_no)
          setGSTPERChange(parseFloat(r.admin_data[0].gst_percent).toString())
          
          setGST(r.admin_data[0].gst_active)
          setPermission(r.permission);
        } else {
        }
      })
      .catch((err) => alert(err));
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const edit_profile = () => {
    if (
      formData.name.trim() === "" ||
      formData.company_name.trim() === "" ||
      formData.address_line_1.trim() === "" ||
      formData.address_line_2.trim() === "" ||
      formData.address_line_3.trim() === "" ||
      formData.state.trim() === "" ||
      formData.city.trim() === ""
    ) {
      alert("Please fill in all the required fields correctly");
    } else if (formData.display_mobile.length !== 10) {
      alert("Enter a mobile number of 10 digits");
    } else {
      setLoading(true);
      let dataSend = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        gst_no:GSTNUMBER,
        gst_percent:GSTPERChange,
        gst_active: GST
      };
      Object.keys(formData).forEach((field) => {
        dataSend[field] = formData[field];
      });

      backend
        .edit_profile(dataSend)
        .then((r) => {
          // alert(JSON.stringify(r));
          setLoading(false);
          if (r.error == "False") {
            window.alert(r.message);
            load_profile();
          } else {
            window.alert(r.message);
          }
        })
        .catch((err) => alert(err));
    }
  };

  const [GST, setGST] = useState(null);
  const [GSTNUMBER, setGSTNUMBER] = useState('');
  const [GSTPERChange, setGSTPERChange] = useState('');


  const dropDownArrayDate = [
    { name: 'GST (Including)', value: '2' },
    { name: 'GST (Excluding)', value: '1' },
    { name: 'Off', value: '0' }
  ];

  const dropDownArrayGst = [
    { name: '0%', value: '0' },
    { name: '5%', value: '5' },
    { name: '12%', value: '12' },
    { name: '18%', value: '18' },
    { name: '28%', value: '28' }
  ];

  const handleGSTChange = (value) => {
    setGST(value);
  };

  const handleGSTNUMBER = (e) => {
    setGSTNUMBER(e.target.value);
  };

  const handleGSTPERChange = (e) => {
    setGSTPERChange(e);
  };

  return (
    <div style={{ height: height, width: width, overflowY: "hidden" }}>
      <Header name={"Profile"} />
      <div style={{ height: scrollheigh, overflowY: "scroll" }}>
        {Object.keys(formData).map((fieldName) => (
          <div
            key={fieldName}
            style={{
              padding: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                color: "var(--primary-color)",

                fontSize: 18,
              }}
            >
              {fieldName && (
                <span>
                  {capitalizeFirstLetter(fieldName.replace(/_/g, " "))}
                </span>
              )}
            </div>
            <InputText
              onChange={handleChange}
              type="text"
              id={fieldName}
              disabled={fieldName === "mobile" || permission != 0}
              name={fieldName} // Use fieldName dynamically here
              value={formData[fieldName]}
              style={{ marginTop: 5, width: "100%", color: "black" }}
            />
          </div>
        ))}

        
        <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: "bold",
              display: "flex",
              // justifyContent: "space-between",
              flexDirection:"column",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            
            <div style={{ width: "100%", textAlign:"left", paddingLeft:10,color: "var(--primary-color)", fontWeight:"normal", fontSize:18 }}>GST</div>
            
            <div style={{width:"100%", padding:10}}>
      <Dropdown
        value={GST}
        disabled={ permission != 0}
        options={dropDownArrayDate}
        onChange={(e) => handleGSTChange(e.value)}
        optionLabel="name"
        placeholder="Off"
        style={{ width: "100%" }}
      />
      {GST !== '0' && (
        <>
          <InputTextarea
            value={GSTNUMBER}
            disabled={ permission != 0}
            onChange={handleGSTNUMBER}
            placeholder="GST Number"
            style={{ width: "100%", height: 40, marginTop: 5 }}
          />
{/*           
          <InputTextarea
            value={GSTPERChange}
            disabled={ permission != 0 || GST != "1"}
            onChange={handleGSTPERChange}
            placeholder="GST in %"
            style={{ width: "100%", height: 40, marginTop: 5 }}
          /> */}
          {
            GST == '1' &&
          
          <Dropdown
          value={GSTPERChange}
          onChange={(e) => handleGSTPERChange(e.value)}
          options={dropDownArrayGst}
          optionLabel="name"
          placeholder="Select GST slab"
          style={{ width: "100%" }}
        />}
        </>
      )}
    </div>
            {permission != 0 ? null : (
          <div style={{width:"100%"}}>
            <Button
              label={"Save Change"}
              onClick={() => edit_profile()}
              style={{
                marginTop: 20,
                // padding: 10,
                // margin: 10,
                width: "98%",
              }}
            />
          </div>
        )}
        <div style={{ height: 20 }}></div>
            
          </div>

      </div>
    </div>
  );
}
