import React from "react";
import { Badge } from "primereact/badge";
import { useNavigate } from "react-router-dom";

export default function PaymentMethodCard({ index, data }) {
  const navigation = useNavigate();
  return (
    <div
      style={{
        minHeight: 50,
        display: "flex",
        position: "relative",
        borderBottomStyle: "solid",
        justifyContent: "center",
        alignItems: "center",
        borderBottomWidth: 0.5,
      }}
    >
      <div
        style={{
          width: 50,
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        {index}
      </div>
      <div
        style={{
          flex: 1,
          height: 50,
          display: "flex",
          flexDirection: "column",
          padding: 5,
          alignItems: "flex-start",
          justifyContent: "center",
        }}
        onClick={() =>
          navigation("/editPaymentMethod", { state: { data: data } })
        }
      >
        <div
          className="pcolor"
          style={{ fontSize: 18, fontWeight: "bold", justifySelf: "center" }}
        >
          {data.payment_name}
        </div>
      </div>

      <Badge
        value={data.status == 1 ? "A" : "D"}
        severity={data.status == 1 ? "success" : "danger"}
        style={{
          position: "absolute",
          right: 15,
          borderRadius: 5,
          alignSelf: "center",
          backgroundColor:
            data.status == 1 ? "var(--primary-color)" : "var(--red-500)",
        }}
      ></Badge>
    </div>
  );
}
