import { useWindowSize } from "@react-hook/window-size";
import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsCartXFill } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { InputText } from "primereact/inputtext";
import { IoSearch, IoCloseSharp } from "react-icons/io5";
import PosBar from "./PosBar";
import Catagorylist from "./Catagorylist";
import POSCard from "./POSCard";
import SidebarPos from "./SidebarPos";
import Backend from "../Backend/Backend";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, clearCart } from "../Redux/Reducer";
import { BluetoothSerial } from "@ionic-native/bluetooth-serial";
import PrintModal from "./PrintModal";
import CustomerModal from "./CustomerModal";
import moment from "moment";
import { MdOutlinePayment } from "react-icons/md";
import BarcodeScanner from "./BarcodeScanner";
import PaymentModal from "./PaymentModal";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
global.Buffer = require("buffer").Buffer;

const backend = new Backend();
export default function PosMain() {
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);
  const [width, height] = useWindowSize();
  const [openbar, setOpenBar] = useState(false);
  const [pdfShow, setPdfShow] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [customerData, setCustomerData] = React.useState({});
  const [receivedData, setReceivedData] = React.useState([]);
  const [productData, setProductData] = React.useState([]);
  const [categoryData, setCategoryData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [roundOff, setRoundOff] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [netAmount, setNetAmount] = React.useState(0);
  const [expenseTotal, setExpenseTotal] = React.useState(0);
  const [invoiceTotal, setInvoiceTotal] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("Cash");
  const [scannedBarcode, setScannedBarcode] = useState("");

  const [selectBluetooth, setSelectBluetooth] = React.useState([]);

  const handleBarcodeScanned = (scannedBarcode) => {
    const newdata = JSON.parse(localStorage.getItem("products")).filter(
      (x) => x.barcode === scannedBarcode
    );
    if (newdata.length > 0) {
      dispatch(addToCart({ ...newdata[0], qty: 1 }));

      return false;
    }
  };
  React.useEffect(() => {
    let products = JSON.parse(localStorage.getItem("products"));
    let category = JSON.parse(localStorage.getItem("category"));

    setProductData(products);
    setCategoryData(category);

    load_sidebar_data();
  }, []);

  const load_sidebar_data = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };

    backend
      .load_sidebar_data(data)
      .then((r) => {
        if (r.error == "False") {
          setExpenseTotal(r.expense);

          setInvoiceTotal(r.invoice);
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      })
      .catch((err) => alert(err));
  };

  const cartItems = useSelector((state) => state.cartItems);

  const groupedGstAmounts = cartItems.reduce((acc, product) => {
    const { gst, gstAmount } = product;
    const existingGstIndex = acc.findIndex((item) => item.hasOwnProperty(gst));

    if (existingGstIndex !== -1) {
      acc[existingGstIndex][gst] += Number(gstAmount); // Convert to number if necessary
    } else {
      acc.push({ [gst]: Number(gstAmount) }); // Convert to number if necessary
    }

    return acc;
  }, []);

  // Calculate total quantity and amount of all items in the cart
  const totalQuantity = cartItems.length;
  const totalAmount = cartItems.reduce((total, item) => total + item.amount, 0);
  const totalGst = cartItems.reduce((gst, item) => gst + item.gstAmount, 0);

  const calc_final = async () => {
    var net_amt = parseFloat(totalAmount);
    var gst = 0;
    if (JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 1) {
      gst = parseFloat(
        (parseFloat(totalAmount).toFixed(2) *
          JSON.parse(localStorage.getItem("admin_data"))?.gst_percent) /
          100
      ).toFixed(2);

      net_amt = net_amt + parseFloat(gst);
    }

    if (JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 2) {
      gst = totalGst;
    }

    if (discount > 0) {
      net_amt = net_amt - discount;
    }

    var intnet = parseInt(net_amt);
    if (net_amt !== intnet) {
      var diffrance = net_amt - intnet;
      if (diffrance >= 0.5) {
        var final_net_amount = intnet + 1;
        var roundoff = 1 - diffrance;
      } else {
        var final_net_amount = intnet;
        var roundoff = diffrance * -1;
      }
    } else {
      var final_net_amount = intnet;
      var roundoff = 0;
    }

    setRoundOff(parseFloat(roundoff));
    setNetAmount(parseFloat(final_net_amount));
    if (final_net_amount > 0) {
      create_invoice(final_net_amount, roundoff, gst);
    } else {
      alert("Net Amount Cannot Be Zero (0)");
    }
  };

  const create_invoice = (net_amount, roundoff, gst) => {
    if (loading == false) {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        items: cartItems,
        total_items: totalQuantity,
        total_amount: totalAmount,
        net_amount: net_amount,
        round_off: roundoff,
        discount: discount,
        gst: gst,
        payment_method: paymentMethod,
        customer_data: customerData != {} ? customerData : "",
      };
      backend
        .create_invoice(data)
        .then((r) => {
          // alert(JSON.stringify(r));
          if (r.error == "False") {
            // dispatch(clearCart());
            setReceivedData(r);

            setOpenPrintModal(true);
            // setTimeout(() => {
            //   bluetoothTest(r);
            // }, 1000);
          } else {
            window.alert(r.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          window.alert(err);
          setLoading(false);
        });
    }
  };

  const filter_product = (search) => {
    setSearchValue(search);

    if (searchValue.length > 1) {
      let data = productData.filter((product) =>
        product.product_name.toLowerCase().includes(searchValue.toLowerCase())
      );

      setProductData(data);
    } else {
      let products = JSON.parse(localStorage.getItem("products"));
      setProductData(products);
    }
  };

  const filter_category = (search) => {
    let products = JSON.parse(localStorage.getItem("products"));
    if (search == "FAV") {
      let data = products.filter((product) => product.fav == 0);

      setProductData(data);
    } else if (search > 1) {
      let data = products.filter((product) => product.category_id == search);

      setProductData(data);
    } else {
      setProductData(products);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem("bluetooth")) {
      setSelectBluetooth(JSON.parse(localStorage.getItem("bluetooth")));
    }
  }, []);

  const bluetoothTest = (r) => {
    if (selectBluetooth.length <= 0) {
      alert("Please select printer or turn on your bluetooth");
    } else {
      BluetoothSerial.isEnabled().then(
        (e) => {
          // alert(e);
          bluetoothIsConnected(r);
        },
        (e) => {
          // alert(e);
          enableBluetooth(r);
        }
      );
    }
  };

  const enableBluetooth = (r) => {
    BluetoothSerial.enable().then(
      (e) => {
        // alert(e);
        bluetoothIsConnected(r);
      },
      (e) => {
        alert("Please Turn On Bluetooth Manually");
        // this.connectBluetooth();
      }
    );
  };

  const bluetoothIsConnected = (r) => {
    BluetoothSerial.isConnected().then(
      (e) => {
        // alert(e);
        testPrintBluetooth(r);
      },
      (e) => {
        // alert(e);
        connectBluetooth(r);
      }
    );
  };

  const connectBluetooth = (r) => {
    BluetoothSerial.connect(selectBluetooth.address).subscribe(
      (e) => testPrintBluetooth(r),
      (r) => console.log(r)
    );
  };

  const testPrintBluetooth = (r) => {
    var adminData = JSON.parse(localStorage.getItem("admin_data"));
    var printItems = cartItems;

    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write(Buffer.from("1B2130", "hex"));
    BluetoothSerial.write(adminData.company_name + "\n");
    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    if (localStorage.getItem("printMobile") == "1") {
      BluetoothSerial.write("Mob:" + adminData.mobile + "\n");
    }

    if (JSON.parse(localStorage.getItem("admin_data"))?.gst_active != 0) {
      BluetoothSerial.write("GST NO:" + adminData.gst_no + "\n");
    }
    if (localStorage.getItem("printAddress") == "1") {
      var address = adminData.address.split("|,|");
      if (address[0]) {
        BluetoothSerial.write(address[0] + "\n");
      }
      if (address[1]) {
        BluetoothSerial.write(address[1] + "\n");
      }
      if (address[2]) {
        BluetoothSerial.write(address[2] + "\n");
      }
    }
    BluetoothSerial.write("-".repeat(30) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));

    BluetoothSerial.write("BILL # " + r.bill_no + "\n");

    if (localStorage.getItem("printDate") != "") {
      BluetoothSerial.write(
        "DATE: " +
          moment(r.bill_date).format(localStorage.getItem("printDate")) +
          "\n"
      );
    }

    if (localStorage.getItem("printCustomer") == "1") {
      if (customerData?.name != "" && customerData?.name != undefined) {
        BluetoothSerial.write("Name: " + customerData.name + "\n");
      }
      if (customerData?.mobile != "" && customerData?.mobile != undefined) {
        BluetoothSerial.write("Mob: " + customerData.mobile + "\n");
      }

      if (customerData?.details != "" && customerData?.details != undefined) {
        BluetoothSerial.write("Detail: " + customerData.details + "\n");
      }
    }

    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));
    BluetoothSerial.write(
      "Item Name" +
        " ".repeat(6) +
        " " +
        "Qty" +
        " " +
        "Rate" +
        " " +
        "    " +
        "Amt" +
        "\n"
    );
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));

    printItems.map((r) => {
      r.qty = parseFloat(r.qty);
      r.amt = parseFloat(r.amount);
      r.rate = parseFloat(r.price);
      var qtystr = "";
      var ratestr = "";
      var amtstr = "";
      var regular = true;

      var varnamelength = r.product_name.length;
      var qtylen = r.qty.toString().length;

      var ratelen = r.rate.toString().length;
      var amtlen = r.amt.toString().length;
      var name1 = "";
      var name2 = "";
      if (varnamelength > 15) {
        name1 = r.product_name.substring(0, 15);

        name2 = r.product_name.substring(15, 30);
      } else {
        name1 = r.product_name;
      }

      if (name1.length == 15) {
      } else {
        name1 = name1 + " ".repeat(15 - name1.length);
      }

      if (qtylen > 4) {
        regular = false;
        qtystr = r.qty.toString();
      } else {
        if (qtylen == 4) {
          qtystr = r.qty.toString();
        } else {
          qtystr = r.qty.toString() + " ".repeat(4 - r.qty.toString().length);
        }
      }

      if (ratelen > 4) {
        regular = false;
        ratestr = r.rate.toString();
      } else {
        if (ratelen == 4) {
          ratestr = r.rate.toString();
        } else {
          ratestr =
            r.rate.toString() + " ".repeat(4 - r.rate.toString().length);
        }
      }

      if (r.amt.toString().length > 6) {
        regular = false;
        amtstr = r.amt.toString();
      } else {
        if (r.amt.toString().length == 6) {
          amtstr = r.amt.toString();
        } else {
          amtstr = " ".repeat(6 - r.amt.toString().length) + r.amt.toString();
        }
      }

      if (regular == true) {
        BluetoothSerial.write(
          name1 + " " + qtystr + " " + ratestr + " " + amtstr + "\n"
        );
        if (name2 != "") {
          BluetoothSerial.write(name2 + "\n");
        }
      } else {
        BluetoothSerial.write(name1 + " " + name2 + "\n");
        BluetoothSerial.write(
          qtystr +
            " x " +
            ratestr +
            " = " +
            parseFloat(amtstr).toFixed(2) +
            "\n"
        );
      }
      regular = true;
    });

    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");
    BluetoothSerial.write(Buffer.from("1B6102", "hex"));
    BluetoothSerial.write(Buffer.from("1B2161", "hex"));
    BluetoothSerial.write("Total : " + totalAmount.toFixed(2) + "\n");
    if (parseFloat(discount) > 0) {
      BluetoothSerial.write("Discount : " + discount + "\n");
    }
    // if (parseFloat(datas.gst) > 0) {
    //   BluetoothSerial.write("CGST @ 2.50 : " + datas.cgst + "\n");
    //   BluetoothSerial.write("SGST @ 2.50 : " + datas.sgst + "\n");
    // }
    if (parseFloat(r.bill_round_off) != 0) {
      BluetoothSerial.write(
        "RoundOff : " + parseFloat(r.bill_round_off).toFixed(2) + "\n"
      );
    }

    if (JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 1) {
      BluetoothSerial.write(
        "Cgst " +
          "@ " +
          parseInt(adminData.gst_percent) / 2 +
          "%  :" +
          parseFloat(r.bill_gst_amt) / 2 +
          "\n"
      );
      BluetoothSerial.write(
        "Sgst " +
          "@ " +
          parseInt(adminData.gst_percent) / 2 +
          "%  :" +
          parseFloat(r.bill_gst_amt) / 2 +
          "\n"
      );
    }

    if (
      JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 2 &&
      groupedGstAmounts.length > 0
    ) {
      groupedGstAmounts.forEach((item) => {
        const gstValue = Object.keys(item)[0]; // Get the GST percentage (e.g., "12.00")
        const gstAmount = item[gstValue]; // Get the corresponding GST amount

        if (parseFloat(gstValue) > 0) {
          // Construct your message using BluetoothSerial.write()
          const message = `Cgst @ ${parseFloat(gstValue) / 2}% : ${parseFloat(
            parseFloat(gstAmount) / 2
          ).toFixed(2)}\n`;
          const message2 = `Sgst @ ${parseFloat(gstValue) / 2}% : ${parseFloat(
            parseFloat(gstAmount) / 2
          ).toFixed(2)}\n`;

          // Assuming BluetoothSerial.write() sends the message
          BluetoothSerial.write(message);
          BluetoothSerial.write(message2);
        }
      });
    }

    BluetoothSerial.write(
      "Net Amount : " + parseFloat(r.bill_net_amount).toFixed(2) + "\n"
    );
    BluetoothSerial.write(Buffer.from("1B6101", "hex"));
    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write("-".repeat(32) + "\n");

    if (localStorage.getItem("printFooter")) {
      let footer = localStorage.getItem("printFooter");
      BluetoothSerial.write(footer + "\n");
    }
    // var settings = JSON.parse(this.state.bluetoothsetting);
    var settings = {
      largefont: false,
      workerid: false,
      time_of_order: false,
      linefeeds:
        localStorage.getItem("printGapLines") > 0
          ? localStorage.getItem("printGapLines")
          : 3,
    };

    if (settings.linefeeds > 0) {
      for (var i = 0; i < settings.linefeeds; i++) {
        BluetoothSerial.write(Buffer.from("\n ", "ascii"));
      }
    }

    BluetoothSerial.write(Buffer.from("1B2100", "hex"));
    BluetoothSerial.write(Buffer.from("1B6100", "hex"));

    setReceivedData(r);
  };

  const downloadPdf = () => {
    alert("PDf");
  };

  const options = {
    // default is `save`
    method: "open",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.NORMAL,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "A4",
      // default is 'portrait'
      orientation: "potrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };

  const gstElements =
    JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 2 &&
    groupedGstAmounts.length > 0
      ? groupedGstAmounts.map((item, index) => {
          const gstValue = Object.keys(item)[0]; // Get the GST percentage (e.g., "12.00")
          const gstAmount = item[gstValue]; // Get the corresponding GST amount

          if (parseFloat(gstValue) > 0) {
            // Construct your message
            return (
              <>
                <div key={index} style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{ flex: 3, fontWeight: "500", display: "flex" }}
                    ></div>
                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        // padding: "5px 0",
                      }}
                    >
                      Cgst @ {parseFloat(gstValue) / 2}% :
                    </div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "end",
                        fontWeight: "bold",
                        // padding: "10px 0",
                      }}
                    >
                      {parseFloat(parseFloat(gstAmount) / 2).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div key={index} style={{ display: "flex" }}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{ flex: 3, fontWeight: "500", display: "flex" }}
                    ></div>
                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        // padding: "5px 0",
                      }}
                    >
                      Sgst @ {parseFloat(gstValue) / 2}% :
                    </div>

                    <div
                      style={{
                        flex: 1,
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "end",
                        fontWeight: "bold",
                        // padding: "10px 0",
                      }}
                    >
                      {parseFloat(parseFloat(gstAmount) / 2).toFixed(2)}
                    </div>
                  </div>
                </div>
              </>
            );
          }
          return null; // Return null if gstValue is not greater than 0
        })
      : null;

  // you can use a function to return the target element besides using React refs
  const getTargetElement = () => document.getElementById("content-id");
  const MyComponent = () => {
    var adminData = JSON.parse(localStorage.getItem("admin_data"));
    const address = adminData.address.split("|,|");

    return (
      <div
        style={{
          flex: 1,
          fontWeight: "500",
          display: "flex",
        }}
      >
        {/* Display the address or any other content here */}
        {address.map((line, index) => (
          <span key={index}>
            {line}
            {index < address.length - 1 ? ", " : ""}
          </span>
        ))}
      </div>
    );
  };
  return (
    <div style={{ height: height, display: "flex", flexDirection: "column" }}>
      <BarcodeScanner onBarcodeScanned={handleBarcodeScanned} />
      <SidebarPos
        expenseTotal={expenseTotal}
        invoiceTotal={invoiceTotal}
        setDiscount={(amount) => {
          setDiscount(amount);
        }}
        status={openbar}
        close={() => setOpenBar(false)}
      />
      <div
        className="bgp"
        style={{
          height: 50,
          display: "flex",
          color: "white",
        }}
      >
        <div
          style={{
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setOpenBar(true)}
        >
          <GiHamburgerMenu fontSize={25} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="p-inputgroup w-full md:w-30rem"
            style={{ height: 35, borderRadius: 10 }}
          >
            <span
              style={{
                background: "white",
                padding: 0,
                color: "gray",
                borderWidth: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 5,
              }}
            >
              <IoSearch fontSize={20} />
            </span>
            <InputText
              id="searchbox"
              className="newdata"
              value={searchValue}
              style={{ padding: 5, borderWidth: 0 }}
              placeholder="Product Search"
              onChange={(e) => filter_product(e.target.value)}
            />

            <span
              style={{
                background: "white",
                padding: 0,
                color: "gray",
                borderWidth: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 5,
              }}
              onClick={() => {
                setSearchValue("");

                let products = JSON.parse(localStorage.getItem("products"));
                setProductData(products);
              }}
            >
              <IoCloseSharp fontSize={20} />
            </span>
          </div>
        </div>
        {/* <div
          style={{
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => generatePDF(getTargetElement, options)}
        >
          {"PDF"}
        </div> */}
        <div
          style={{
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            dispatch(clearCart());
            setDiscount(0);
            setPaymentMethod("Cash");
          }}
        >
          <BsCartXFill fontSize={25} />
        </div>
        <div
          style={{
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() =>
            totalAmount > 0
              ? setOpenCustomerModal(true)
              : window.alert("Cart is Empty")
          }
        >
          <FaUserEdit fontSize={25} />
        </div>
        <div
          style={{
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() =>
            totalAmount > 0
              ? setOpenPaymentModal(true)
              : window.alert("Cart is Empty")
          }
        >
          <MdOutlinePayment fontSize={25} />
        </div>
      </div>
      <Catagorylist
        filter_category={(value) => filter_category(value)}
        categoryData={categoryData}
      />
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          padding: 5,
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {productData.map((_, index) => (
            <POSCard key={_.id} data={_} />
          ))}
        </div>
      </div>
      <PosBar
        createInvoice={() => calc_final()}
        totalQuantity={totalQuantity}
        totalGst={totalGst}
        totalAmount={totalAmount - discount}
        isLoading={loading}
      />
      <PrintModal
        visible={openPrintModal}
        totalAmount={totalAmount}
        rePrintBill={() => testPrintBluetooth(receivedData)}
        downloadPdf={() => {
          setPdfShow(true);
          setTimeout(() => {
            generatePDF(getTargetElement, options);
          }, 100);
        }}
        billNo={receivedData?.bill_no}
        closeModal={() => {
          setOpenPrintModal(false);
          dispatch(clearCart());
          setDiscount(0);
          setPaymentMethod("Cash");
          setCustomerData({});
          setLoading(false);
          setPdfShow(false);
        }}
      />
      <CustomerModal
        visible={openCustomerModal}
        closeModal={() => {
          setOpenCustomerModal(false);
        }}
        addCustomer={(formData) => {
          setCustomerData(formData);
          setOpenCustomerModal(false);
        }}
      />
      <PaymentModal
        visible={openPaymentModal}
        closeModal={() => {
          setOpenPaymentModal(false);
        }}
        paymentMethod={paymentMethod}
        addPayment={(payemntData) => {
          setPaymentMethod(payemntData);
          setOpenPaymentModal(false);
        }}
      />
      <div hidden={!pdfShow} style={{ zIndex: -99999 }}>
        <div
          className="invoice-box"
          style={{ position: "absolute" }}
          id="content-id"
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                fontSize: 35,
              }}
            >
              {JSON.parse(localStorage.getItem("admin_data"))?.company_name}
            </div>
            <div
              style={{
                flex: 1,
                height: 80,
                display: "flex",
                fontSize: 25,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              INVOICE
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <div>
                <b>BILLED TO</b>
              </div>
              <div>{customerData?.name}</div>
              <div>{customerData?.mobile}</div>
              <div>{customerData?.details}</div>
              <div>{customerData?.gst && "GST : " + customerData?.gst}</div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <div>Details</div>
              <div>Invoice No. : {receivedData?.bill_no}</div>
              <div>
                {"GST : " +
                  JSON.parse(localStorage.getItem("admin_data"))?.gst_no}
              </div>
              <div>
                Date : {moment(receivedData?.bill_date).format("DD-MMM-YYYY")}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: 20 }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                borderTop: "2px solid black",
                borderBottom: "2px solid black",
                padding: "10px 0",
              }}
            >
              <div style={{ flex: 3, display: "flex" }}>
                <b>Item</b>
              </div>

              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <b>Qty</b>
              </div>

              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <b>Price</b>
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <b>Total</b>
              </div>
            </div>
          </div>
          {cartItems.map((i, t) => (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                  borderBottom: "1px solid gray",
                  padding: "10px 0",
                }}
              >
                <div style={{ flex: 3, fontWeight: "500", display: "flex" }}>
                  {i.product_name}
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {i.qty}
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {parseFloat(i.price).toFixed(2)}
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {parseFloat(i.amount).toFixed(2)}
                </div>
              </div>
            </div>
          ))}

          {/* subtotal */}
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                // borderBottom: "1px solid gray",
                padding: "10px 0",
              }}
            >
              <div
                style={{ flex: 3, fontWeight: "500", display: "flex" }}
              ></div>
              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                SubTotal
              </div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: "bold",
                }}
              >
                {totalAmount - discount}
              </div>
            </div>
          </div>
          {JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 1 ? (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ flex: 3, fontWeight: "500", display: "flex" }}
                ></div>
                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    padding: "10px 0",
                  }}
                >
                  GST
                </div>

                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "end",
                    fontWeight: "bold",
                    padding: "10px 0",
                  }}
                >
                  {receivedData?.bill_gst_amt}
                </div>
              </div>
            </div>
          ) : null}
          {gstElements}
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ flex: 3, fontWeight: "500", display: "flex" }}
              ></div>
              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  borderTop: "2px solid black",
                  padding: "10px 0",
                }}
              >
                Net Total
              </div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: "bold",
                  borderTop: "2px solid black",
                  padding: "10px 0",
                }}
              >
                {receivedData?.bill_net_amount}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                padding: "10px 0",
              }}
            >
              <div
                style={{
                  flex: 3,
                  fontSize: 22,
                  fontWeight: "500",
                  display: "flex",
                }}
              >
                Thank You!{" "}
              </div>
              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              ></div>

              <div
                style={{
                  flex: 1,
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: "bold",
                }}
              ></div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              marginTop: 40,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                // padding: "10px 0",
              }}
            >
              <div
                style={{
                  flex: 3,
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    // alignItems: "flex-start",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  {JSON.parse(localStorage.getItem("admin_data"))?.company_name}
                </div>
                <div
                  style={{
                    flex: 1,
                    fontWeight: "500",
                    display: "flex",
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  {"Contact : " +
                    JSON.parse(localStorage.getItem("admin_data"))?.mobile}
                </div>
                <MyComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
