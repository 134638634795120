import React from "react";
import RING from "./ring.png";

export default function SaleCard({
  colorb = "var(--primary-color)",
  today,
  yesterday,
  name,
  name2,
}) {
  return (
    <div
      style={{
        borderRadius: 20,
        aspectRatio: 1.1,
        marginRight: 30,
        position: "relative",
        objectFit: "scale-down",
        background: colorb,
        padding: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        minWidth: 160,
        overflow: "hidden",
        // marginRight: -39,
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: 14, color: "white" }}>
        {name ? name : null}
      </div>

      <div style={{ fontWeight: "bold", fontSize: 20, color: "white" }}>
        {today}
      </div>

      <div
        style={{
          fontWeight: "bold",
          fontSize: 14,
          color: "white",
          marginTop: 10,
        }}
      >
        {name2 ? name2 : null}
      </div>

      <div style={{ fontWeight: "bold", fontSize: 20, color: "white" }}>
        {yesterday}
      </div>

      <div
        style={{
          overflowX: "auto",

          height: "100%",
          bottom: 0,
        }}
      >
        <img
          src={RING}
          alt="Ring Image"
          style={{
            // width: 300,
            position: "absolute",
            bottom: -50,
            right: -160,
            opacity: 0.2,
            filter: "invert(1) brightness(1000%)",
            height: 160,
            overflow: "hidden",
            objectFit: "contain",
            transform: "rotate(25deg)", // You can use "contain" or "cover" based on your preference
          }}
        />
      </div>
    </div>
  );
}
