import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { FaPlus } from "react-icons/fa";
import { MdArrowBackIos } from "react-icons/md";

export default function Header({ onClick, name }) {
  const [width, height] = useWindowSize();
  return (
    <div
      style={{
        height: 60,
        width: width,
        display: "flex",
        borderBottomStyle: "solid",
        borderBottomWidth: 0.5,
      }}
    >
      <div
        style={{
          width: 60,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "gray",
        }}
        onClick={() => window.history.back()}
      >
        <MdArrowBackIos fontSize={25} />
      </div>
      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          flex: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        {name}
      </div>
      {onClick ? (
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <div
            style={{
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "var(--primary-color)",
              borderRadius: 50,
            }}
            onClick={onClick}
          >
            <FaPlus fontSize={18} />
          </div>
        </div>
      ) : null}
    </div>
  );
}
