import React from "react";
import { Badge } from "primereact/badge";

export default function Catagorylist({ categoryData, filter_category }) {
  return (
    <div
      style={{
        height: 50,
        overflowY: "hidden",
        overflowX: "scroll",
        display: "flex",
        // alignItems: "center",
        padding: 10,
      }}
    >
      <div
        onClick={() => filter_category("ALL")}
        style={{ paddingLeft: 5, paddingRight: 5 }}
      >
        <Badge
          style={{ paddingLeft: 10, paddingRight: 10 }}
          value="ALL"
        ></Badge>
      </div>
      <div
        onClick={() => filter_category("FAV")}
        style={{ paddingLeft: 5, paddingRight: 5 }}
      >
        <Badge value="FAV"></Badge>
      </div>
      {categoryData.map((_, index) => (
        <div
          style={{ paddingLeft: 5, paddingRight: 5 }}
          onClick={() => filter_category(_.id)}
        >
          <Badge value={_.category_name?.toUpperCase()}></Badge>
        </div>
      ))}
    </div>
  );
}
