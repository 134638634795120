import { useWindowSize } from "@react-hook/window-size";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import Backend from "../Backend/Backend";
import { Dropdown } from "primereact/dropdown";

const backend = new Backend();
export default function AddProducts(props) {
  const [width, height] = useWindowSize();

  const [categoryData, setCategoryData] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState({});

  const [productName, setProductName] = React.useState("");
  const [barcode, setBarcode] = React.useState("");
  const [unicodeName, setUnicodeName] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [hsnCode, setHsnCode] = React.useState("");
  const [gst, setGst] = React.useState(0);
  const [fav, setFav] = React.useState(false);
  const [variableRate, setVariableRate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [selectedItem, setSelectedItem] = React.useState(null);
  const items = Array.from({ length: 100000 }).map((_, i) => ({
    label: `Item #${i}`,
    value: i,
  }));

  React.useEffect(() => {
    load_category();
  }, []);

  const load_category = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend.load_category(data).then((r) => {
      if (r.error == "False") {
        setCategoryData(r.category);
      } else {
        window.alert(r.message);
      }
      setLoading(false);
    });
  };

  const add_product = () => {
    if (productName == "" || productName.length < 1) {
      window.alert("Enter Product Name Properly");
    } else if (price <= 0) {
      window.alert("Enter Product Price Greater Than 0");
    }  else if (gst < 0 || gst > 100) {
      window.alert("Enter Product GST Between 0 and 100");
    } else if (selectedCategory?.id <= 0) {
      window.alert("Select category");
    } else if (selectedCategory?.name == "") {
      window.alert("Select category");
    } else {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        product_name: productName,
        category_name: selectedCategory?.name,
        category_id: selectedCategory?.id,
        price: price,
        unicode_name: unicodeName,
        barcode: barcode,
        gst:gst,
        hsn_code:hsnCode,
        fav: fav == true ? 1 : 0,
        variable_price: variableRate == true ? 1 : 0,
      };
      backend.add_product(data).then((r) => {
        if (r.error == "False") {
          window.alert(r.message);

          setProductName("");
          setPrice("");
          setUnicodeName("");
          setBarcode("");
          setFav(false);
          setVariableRate(false);
          setSelectedCategory({});
          window.history.back();
        } else {
          window.alert(r.message);
        }
        setLoading(false);
      });
    }
  };

  const dropDownArrayGst = [
    { name: '0%', value: '0' },
    { name: '5%', value: '5' },
    { name: '12%', value: '12' },
    { name: '18%', value: '18' },
    { name: '28%', value: '28' }
  ];

  return (
    <div style={{ height: height, width: width }}>
      <div
        style={{
          height: 60,
          width: width,
          display: "flex",
          //   borderBottomStyle: "solid",
          //   borderBottomWidth: 0.5,
        }}
      >
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "gray",
          }}
          onClick={() => window.history.back()}
        >
          <MdArrowBackIos fontSize={25} />
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          Add Products
        </div>
        <div
          style={{
            width: 60,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        ></div>
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Product Name
        </div>
        <InputText
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Unicode Name
        </div>
        <InputText
          value={unicodeName}
          onChange={(e) => setUnicodeName(e.target.value)}
          placeholder="(Optional)"
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Barcode
        </div>
        <InputText
          value={barcode}
          onChange={(e) => setBarcode(e.target.value)}
          placeholder="(Optional)"
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          HSN Code
        </div>
        <InputText
          value={hsnCode}
          onChange={(e) => setHsnCode(e.target.value)}
          placeholder="(Optional)"
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Rate/Price
        </div>
        <InputText
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
      {JSON.parse(localStorage.getItem("admin_data"))?.gst_active == 2 ?
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Product GST
        </div>
       
        <Dropdown
          value={gst}
          onChange={(e) => setGst(e.value)}
          options={dropDownArrayGst}
          optionLabel="name"
          placeholder="Select GST slab"
          style={{ width: "100%" }}
        />
      </div>:null}
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Category
        </div>
        <Dropdown
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.value)}
          options={categoryData}
          optionLabel="name"
          placeholder="Select Category"
          style={{ width: "100%" }}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Variable Price
        </div>
        <InputSwitch
          checked={variableRate}
          onChange={() => setVariableRate(!variableRate)}
        />
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Favorite
        </div>
        <InputSwitch checked={fav} onChange={() => setFav(!fav)} />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={() => add_product()}
          label="Add Product"
          style={{ marginTop: 10, width: "100%" }}
        />
      </div>
    </div>
  );
}
