import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { MdArrowBackIos, MdArrowBackIosNew } from "react-icons/md";
import Backend from "../Backend/Backend";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import Header from "../Component/Header";
import { FcComboChart, FcTodoList, FcBearish } from "react-icons/fc";
import { IoIosArrowForward } from "react-icons/io";

const backend = new Backend();
export default function Reportsuperadmin() {
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  //   const

  return (
    <>
      <div style={{ height: height, width: width }}>
        <Header name={"Reports"} />

        <div
          style={{
            height: 60,
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            display: "flex",
            padding: 5,
            fontSize: 20,
            fontWeight: "bold",
            alignItems: "center",
          }}
          onClick={() => navigation("/lol")}
        >
          <div style={{ width: 50, display: "flex", justifyContent: "center" }}>
            <div
              style={{
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 100,
                color: "white",
              }}
            >
              <FcComboChart size={25} />
            </div>
          </div>
          <div style={{ flex: 1, textAlign: "left" }}>Invoice Report</div>
          <div style={{ width: 40 }}>
            <IoIosArrowForward />
          </div>
        </div>

        <div
          style={{
            height: 60,
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            display: "flex",
            padding: 5,
            fontSize: 20,
            fontWeight: "bold",
            alignItems: "center",
          }}
          onClick={() => navigation("/itemwiseReport")}
        >
          <div style={{ width: 50, display: "flex", justifyContent: "center" }}>
            <div
              style={{
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 100,
                color: "white",
              }}
            >
              <FcTodoList size={25} />
            </div>
          </div>
          <div style={{ flex: 1, textAlign: "left" }}>Item Wise Report</div>
          <div style={{ width: 40 }}>
            <IoIosArrowForward />
          </div>
        </div>
        <div
          style={{
            height: 60,
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            display: "flex",
            padding: 5,
            fontSize: 20,
            fontWeight: "bold",
            alignItems: "center",
          }}
          onClick={() => navigation("/expense")}
        >
          <div style={{ width: 50, display: "flex", justifyContent: "center" }}>
            <div
              style={{
                height: 30,
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 100,
                color: "white",
              }}
            >
              <FcBearish size={25} />
            </div>
          </div>
          <div style={{ flex: 1, textAlign: "left" }}>Expense Report</div>
          <div style={{ width: 40 }}>
            <IoIosArrowForward />
          </div>
        </div>
      </div>
    </>
  );
}
