import { InputText } from "primereact/inputtext";
import React from "react";
import Backend from "../Backend/Backend";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import CustomerCard from "./CustomerCard";

const backend = new Backend();

export default function CustomerModal({ visible, closeModal, addCustomer }) {
  const [loading, setLoading] = React.useState(false);
  const [suggetion, SetSuggetion] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: "",
    mobile: "",
    details: "",
    gst: "",
  });

  const find_customer = () => {
    if (formData?.mobile > 6) {
      setLoading(true);
      let data = {
        authtoken: localStorage.getItem("admin_token"),
        admin_id: localStorage.getItem("admin_id"),
        mobile: formData?.mobile,
      };
      backend.load_customer_details(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          // alert(r.customer);
          SetSuggetion(r.customer);
        } else {
        }
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });

    if (field === "mobile" && value.length > 6) {
      find_customer();
    } else {
      SetSuggetion([]);
    }
  };
  const handletouchcustomer = (i) => {
    setFormData({
      name: i.customer_name,
      mobile: i.customer_mobile,
      details: i.customer_details,
      gst: i.customer_gst,
    });
    SetSuggetion([]);
  };

  const handleSubmit = () => {
    addCustomer(formData);
    setFormData({
      name: "",
      mobile: "",
      details: "",
      gst: "",
    });
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => closeModal()}
        className="p-button-text"
      />
      <Button
        label="Add"
        icon="pi pi-check"
        onClick={() => handleSubmit()}
        autoFocus
      />
    </div>
  );

  return (
    <Dialog
      header={" Customer Details"}
      visible={visible}
      style={{ width: "90%" }}
      onHide={() => closeModal(false)}
      position={"bottom"}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        {suggetion.length > 0 ? (
          <div
            style={{
              position: "absolute",
              top: 55,

              left: 15,
              boxSizing: "border-box",
              background: "white",
              borderStyle: "solid",
              alignSelf: "center",
              width: "90%",
              borderWidth: 1,
              borderColor: "var(--primary-color)",
              borderRadius: 3,
              height: 150,
              overflowY: "scroll",
            }}
          >
            {suggetion.map((i, t) => (
              <CustomerCard
                handletouchcustomer={() => handletouchcustomer(i)}
                data={i}
                key={i.customer_mobile}
              />
            ))}
          </div>
        ) : null}
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Mobile
        </div>
        <InputText
          type="number"
          value={formData.mobile}
          onChange={(e) => handleChange("mobile", e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
        {/* <div style={{ position: "absolute", width: "80%", bottom: 0 }}>hi</div> */}
      </div>
      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          Name
        </div>
        <InputText
          type="text"
          value={formData.name}
          onChange={(e) => handleChange("name", e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",

            fontSize: 18,
          }}
        >
          GST No.
        </div>
        <InputText
          type="text"
          value={formData.gst}
          onChange={(e) => handleChange("gst", e.target.value)}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>

      <div
        style={{
          padding: 10,
          paddingTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            color: "var(--primary-color)",
            fontSize: 18,
          }}
        >
          Details
        </div>
        <InputTextarea
          value={formData.details}
          onChange={(e) => handleChange("details", e.target.value)}
          rows={4}
          cols={30}
          style={{ marginTop: 5, width: "100%" }}
        />
      </div>
    </Dialog>
  );
}
