import { useWindowSize } from "@react-hook/window-size";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import ProductsCard from "./ProductsCard";
import Backend from "../Backend/Backend";
import AddProducts from "./AddProducts";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useNavigate } from "react-router-dom";
import Header from "../Component/Header";
import { InputText } from "primereact/inputtext";
import { BsSearch } from "react-icons/bs";

const backend = new Backend();
export default function Products() {
  const navigation = useNavigate();
  const [width, height] = useWindowSize();
  const [productData, setProductData] = React.useState([]);
  const [productData2, setProductData2] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openAddProduct, setOpenAddProduct] = React.useState(false);
  const [searchvalue, setSearchvalue] = React.useState("");

  React.useEffect(() => {
    load_products();
  }, []);

  const SEARCHTEXT = (e) => {
    const data = e.target.value;
    setSearchvalue(data);
    if (data.length > 1) {
      var tempdata = productData2.filter(
        (x) =>
          x.product_name.toLowerCase().includes(data.toLowerCase()) ||
          parseFloat(x.price) == parseFloat(data)
      );
      setProductData(tempdata);
    } else {
      setProductData(productData2);
    }
  };
  const load_products = () => {
    setLoading(true);
    let data = {
      authtoken: localStorage.getItem("admin_token"),
      admin_id: localStorage.getItem("admin_id"),
    };
    backend.load_product(data).then((r) => {
      if (r.error == "False") {
        setProductData(r.products);
        setProductData2(r.products);
      } else {
        window.alert(r.message);
      }
      setLoading(false);
    });
  };
  return (
    <>
      <div style={{ height: height, width: width }}>
        <Header name="Products" onClick={() => navigation("/addproduct")} />
        <div
          style={{
            padding: 7,
            marginBottom: 10,
            borderBottomStyle: "solid",
            borderBottomWidth: 0.5,
            background: "var(--primary-color)",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <span
            className="p-input-icon-left"
            style={{
              width: "100%",
            }}
          >
            <BsSearch />
            <InputText
              value={searchvalue}
              onChange={SEARCHTEXT}
              placeholder="Search Products"
              style={{ width: "100%", height: 35 }}
            />
          </span>
        </div>
        {productData.map((i, t) => (
          <ProductsCard key={i.id} index={t + 1} data={i} />
        ))}
      </div>
    </>
  );
}
